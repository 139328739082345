import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokPodcastTeaserComponent } from './podcast-teaser.component';
import { AokTileModule } from '../tile/tile.module';
import { AokArticleContentModule } from '../article-content/article-content.module';
import { AokSvgIconComponent } from '@aok/common';

@NgModule({
  declarations: [AokPodcastTeaserComponent],
  exports: [AokPodcastTeaserComponent],
  imports: [CommonModule, AokArticleContentModule, AokTileModule, AokSvgIconComponent],
})
export class AokPodcastTeaserModule {}
