import { AokAccount, AokAccountStatus } from './account.schema';
import { AddressData, ContactData } from './contact';
import { AokPageQueryParams } from './page.schema';

export interface AokBaseOrg extends AokAccount {
  name: string;
  bsnr: string;
  organizationType: KnownOrgType | string;
  address: AddressData;
  contactData: ContactData;
  userData: UserData;
}

export interface AokOrg extends AokBaseOrg {
  practitioners: number;
}

export interface AokCmsOrg extends AokBaseOrg {
  userCount: number;
}

export enum KnownOrgType {
  DoctorPractice = 'DOCTOR_PRACTICE',
  ProfessionalPracticeAssociation = 'PROFESSIONAL_PRACTICE_ASSOCIATION',
  CollaborativeDoctorPractice = 'GROUP_PRACTICE',
  MedicalCareCenter = 'MEDICAL_CARE_CENTER',
}

export interface AokOrgListOptions extends AokPageQueryParams {
  status?: AokAccountStatus[] | AokAccountStatus;
  bsnr?: string[] | string;
  term?: string;
  organizationId?: string[];
}

export interface UserData {
  title: string;
  name: string;
  lanr: string;
  userType: string;
  searchMatch: boolean;
}
