import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { AokArticleType, AokStaticArticle, AokStaticArticleKey } from '../schemas';
import { AOK_ARTICLE_API_URL, AokArticleClientBase } from './base-article.client';

export const AOK_STATIC_ARTICLES = new InjectionToken<AokStaticArticleKey[]>('AOK_STATIC_ARTICLES');

@Injectable({ providedIn: 'root' })
export class AokStaticArticleClient extends AokArticleClientBase {
  constructor(
    @Optional() @Inject(AOK_STATIC_ARTICLES) protected staticArticleKeys: AokStaticArticleKey,
    @Inject(AOK_ARTICLE_API_URL) baseUrl: string,
    http: HttpClient
  ) {
    super(baseUrl, http);
  }

  find(idOrKey: NonNullable<number | AokStaticArticleKey>): Observable<AokStaticArticle> {
    return this.http.get<AokStaticArticle>(this.resolveUrl(idOrKey.toString()));
  }

  list(type: AokArticleType): Observable<AokStaticArticle[]> {
    const params = new HttpParams({ fromObject: { type } });
    return this.http.get<AokStaticArticle[]>(this.resolveUrl(''), { params });
  }

  resolveUrl(...segments: string[]): string {
    return super.resolveUrl('external', ...segments);
  }
}
