<div class="aok-datepicker" (mouseenter)="hoverInput()" (mouseleave)="stopInputHover()">
  <input
    matInput
    id="{{ innerId }}"
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    placeholder="{{ placeholder }}"
    autocomplete="off"
    [formControl]="control"
    [class.hover]="isInputHover"
    [class.withValue]="control.value"
  />
  <mat-datepicker-toggle [for]="picker" disableRipple>
    <aok-svg-icon
      id="calender"
      matDatepickerToggleIcon
      class="calendarPicker"
      size="16px"
      title="Kalender"
    ></aok-svg-icon>
  </mat-datepicker-toggle>
</div>

<mat-datepicker #picker [calendarHeaderComponent]="header"></mat-datepicker>
