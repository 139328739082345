import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { A11yUtilsModule } from '../../directives';
import { PopoverDirection } from '../../schemas';
import { isString } from '../../utils';
import { AokSvgIconComponent } from '../svg-icon/svg-icon.component';
import { AokPopoverContentTemplateComponent } from './popover-content-template/popover-content-template.component';

@Component({
  selector: 'aok-popover',
  styleUrls: ['./popover.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './popover.component.html',
  standalone: true,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule],
})
export class AokPopoverComponent {
  /**
   * has default anchor
   */
  @Input() anchor = true;
  @Input() iconName = 'question-circle_filled';
  @Input() iconSize = 20;
  /**
   * set continues a hover class and show the popover
   */
  @Input() hover = false;
  // Sets the center point within the anchor
  public centerPositionTop = '50%'; // Reset isn't needed
  public centerPositionLeft = '50%'; // Reset isn't needed
  public yReverse = '';
  /**
   * The following four variables describes the position of the Popover Pointer
   */
  public yTopPointer = '';
  public yBottomPointer = '';
  public xLeftPointer = '';
  public xRightPointer = '';
  public _width = '400px';
  // sets the side where the popover appears
  public popoverSide: 'left' | 'right' | 'top' | 'bottom' = 'right';
  // sets the position on the popover side (not the side self) where the pointer is
  public popoverSideAlignment: 'left' | 'right' | 'top' | 'bottom' | 'center' = 'top';
  public visiblePopover = false;
  @ViewChild('popoverTemplate', { static: true }) staticContent: AokPopoverContentTemplateComponent;
  @ViewChild('popoverTemplate', { static: false }) dynamicContent: AokPopoverContentTemplateComponent;
  @ViewChild('visibleController') controller: ElementRef;
  // Specifies the distance between the anchor (center) and the popover. (without taking the pointer into account)
  private _DEFAULT_GAP = 32;
  // Sets both sides of the pointer
  private _POINTER_BORDER_WIDTH = 24;
  // Specifies the distance between the edge and the beginning of the pointer.
  private _BORDER_TO_POINTER_GAP = 26;
  public yAxesPopover = Math.sqrt(2) * this._POINTER_BORDER_WIDTH + this._BORDER_TO_POINTER_GAP + 'px';
  // Specifies the default Radius around the center
  private _DEFAULT_RADIUS = 14;
  // Describes how big the anchor is.
  private _radius = this._DEFAULT_RADIUS; // Reset isn't needed
  /**
   * The following four variables describes the position of the Popover Container
   */
  public xAxesPopover = this._radius + this._DEFAULT_GAP + 'px';

  constructor(private cd: ChangeDetectorRef) {}

  /**
   * This input specifies where the popover and pointer appear.
   * The first value is the side on which the popover should appear in relation to the anchor.
   * e.g. Right = Right of the anchor.
   *
   * The second value specifies where on the page the pointer appears.
   * e.g. if the popover appears to the right of the anchor,
   * the pointer can appear at the top of the left side of the popover (top),
   * in the middle (center) or at the bottom (bottom).
   * @param direction
   */
  @Input() set direction(direction: PopoverDirection) {
    if (Object.values(PopoverDirection).includes(direction)) {
      const directionAlignment = direction.split('-');
      this.popoverSide = directionAlignment[0] as 'left' | 'right' | 'top' | 'bottom';
      this.popoverSideAlignment = directionAlignment[1] as 'left' | 'right' | 'top' | 'bottom' | 'center';
      this.calculatePopoverPosition();
    } else {
      this.popoverSide = 'left';
      this.popoverSideAlignment = 'top';
      this.calculatePopoverPosition();
    }
  }

  /**
   * If the popover is not 100% aligned it can be moved here on the X axis.
   * The unit "px" is used automatically, so it is not necessary to pass it.
   * @param position
   */
  @Input() set xaxis(position: number | string) {
    this.centerPositionLeft = AokPopoverComponent.calculateAnchorPosition(position);
  }

  /**
   * If the popover is not 100% aligned it can be moved here on the Y axis.
   * The unit "px" is used automatically, so it is not necessary to pass it.
   * @param position
   */
  @Input() set yaxis(position: number | string) {
    this.centerPositionTop = AokPopoverComponent.calculateAnchorPosition(position);
  }

  @Input() set radius(radius: number | string) {
    const number = AokPopoverComponent.getFullNumber(radius);
    this._radius = number === 0 ? this._DEFAULT_RADIUS : number;

    this.calculatePopoverPosition();
  }

  /**
   * Here you can set the width of the popover. It will be used automatically in "px".
   * @param width
   */
  @Input() set width(width: number | string) {
    const number = AokPopoverComponent.getFullNumber(width);
    this._width = number === 0 ? '400px' : `${width}px`;
  }

  public get popoverContentExist(): boolean {
    return !!this.staticContent || !!this.dynamicContent;
  }

  private static getFullNumber(position: number | string): number {
    if (isString(position) && !Number.isNaN(Number.parseInt(position, null))) return Number.parseInt(position, null);
    else if (typeof position === 'number') return position;
    else return 0;
  }

  private static calculateAnchorPosition(position: number | string): string {
    const xAxes = AokPopoverComponent.getFullNumber(position);
    return xAxes === 0 ? '50%' : `calc(50% + ${xAxes}px)`;
  }

  public togglePopover(): void {
    this.visiblePopover = !this.visiblePopover;
    this.cd.detectChanges();
  }

  public closeFocus(): void {
    this.visiblePopover = false;
    this.cd.detectChanges();
  }

  /**
   * Resetting the last values
   */
  private resetPopoverAlignment(): void {
    this.yBottomPointer = '';
    this.yTopPointer = '';
    this.xLeftPointer = '';
    this.xRightPointer = '';

    this.yAxesPopover = '';
    this.xAxesPopover = '';

    this.yReverse = '';
  }

  /**
   * Calculates everything via the popover
   * @private
   */
  private calculatePopoverPosition(): void {
    this.resetPopoverAlignment();
    this.setTransformer();
    this.setPopoverDistance();
    this.setPointerOnBorder();
  }

  /**
   * Sets the transform property for the popover
   */
  private setTransformer(): void {
    if (this.popoverSideAlignment === 'center') {
      if (this.popoverSide === 'left') {
        this.yReverse = 'translate(-100%, -50%)';
      } else if (this.popoverSide === 'right') {
        this.yReverse = 'translateY(-50%)';
      } else if (this.popoverSide === 'top') {
        this.yReverse = 'translate(-50%, -100%)';
      } else {
        this.yReverse = 'translateX(-50%)';
      }
    } else {
      if (
        (this.popoverSide === 'right' && this.popoverSideAlignment === 'bottom') ||
        (this.popoverSide === 'top' && this.popoverSideAlignment === 'right')
      )
        this.yReverse = 'translateY(-100%)';
      else if (
        (this.popoverSide === 'left' && this.popoverSideAlignment === 'top') ||
        (this.popoverSide === 'bottom' && this.popoverSideAlignment === 'left')
      )
        this.yReverse = 'translateX(-100%)';
      else if (
        (this.popoverSide === 'left' && this.popoverSideAlignment === 'bottom') ||
        (this.popoverSide === 'top' && this.popoverSideAlignment === 'left')
      )
        this.yReverse = 'translate(-100%, -100%)';
    }
  }

  /**
   * Sets the distance from center to popover border
   */
  private setPopoverDistance(): void {
    const distancePopover = this._radius + this._DEFAULT_GAP;
    switch (this.popoverSide) {
      case 'right':
        this.xAxesPopover = distancePopover + 'px';
        break;
      case 'left':
        this.xAxesPopover = '-' + distancePopover + 'px';
        break;
      case 'bottom':
        this.yAxesPopover = distancePopover + 'px';
        break;
      case 'top':
        this.yAxesPopover = '-' + distancePopover + 'px';
    }
  }

  /**
   * Sets the pointer along the popover frame
   */
  private setPointerOnBorder(): void {
    const halfPointer = (Math.sqrt(2) * this._POINTER_BORDER_WIDTH) / 2;
    switch (this.popoverSideAlignment) {
      case 'bottom': {
        this.yAxesPopover = halfPointer + this._BORDER_TO_POINTER_GAP - 3 + 'px';
        this.yBottomPointer = this._BORDER_TO_POINTER_GAP + 'px';
        break;
      }
      case 'top': {
        this.yAxesPopover = '-' + (halfPointer + this._BORDER_TO_POINTER_GAP - 3) + 'px';
        this.yTopPointer = this._BORDER_TO_POINTER_GAP + 'px';
        break;
      }
      case 'right': {
        this.xAxesPopover = -(halfPointer + this._BORDER_TO_POINTER_GAP - 5) + 'px';
        this.xRightPointer = this._BORDER_TO_POINTER_GAP + 'px';
        break;
      }
      case 'left': {
        this.xAxesPopover = halfPointer + this._BORDER_TO_POINTER_GAP - 1 + 'px';
        this.xLeftPointer = this._BORDER_TO_POINTER_GAP + 'px';
        break;
      }
      case 'center': {
        if (this.popoverSide === 'left' || this.popoverSide === 'right') {
          this.yTopPointer = `calc(50% - ${halfPointer - 5}px)`;
          this.yAxesPopover = '0';
        } else if (this.popoverSide === 'top' || this.popoverSide === 'bottom')
          this.xLeftPointer = `calc(50% - ${halfPointer - 3}px)`;
      }
    }
  }
}
