import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { scrollToTop } from '@aok/common';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-help-page',
  styleUrls: ['./help-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './help-page.component.html',
})
export class HelpPage implements OnDestroy {
  readonly pageTitle = this.route.data.pipe(map((data) => data['title'][this.route.snapshot.params.category]));

  private ngDestroyed: Subject<void>;

  constructor(readonly router: Router, protected route: ActivatedRoute) {
    this.ngDestroyed = new Subject<void>();
    this.handleParamChange();
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  private handleParamChange(): void {
    this.route.params.pipe(takeUntil(this.ngDestroyed)).subscribe(() => scrollToTop());
  }
}
