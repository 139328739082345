import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { A11yUtilsModule, AokSvgIconComponent, DialogOverlayModule } from '@aok/common';
import { AokInputFieldModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { DeleteKvnProfileDialog } from './delete-kvn-profile-dialog.component';

@NgModule({
  declarations: [DeleteKvnProfileDialog],
  imports: [
    CommonModule,
    FormsModule,
    DialogOverlayModule,
    A11yModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    AokDialogLayoutModule,
    AokCheckboxModule,
    AokInputFieldModule,
  ],
})
export class DeleteKvnProfileDialogModule {}
