import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AokArticleContentModule } from '../../article-content/article-content.module';
import { AokObjectUrlPipeModule } from '../../../pipes';
import { AokSlicePipeModule, AokSvgIconComponent } from '@aok/common';
import { AokArticleTeaserComponent } from './article-teaser.component';
import { RouterModule } from '@angular/router';
import { AokTileModule } from '../../tile/tile.module';

const declarations = [AokArticleTeaserComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [
    CommonModule,
    AokArticleContentModule,
    AokObjectUrlPipeModule,
    AokSlicePipeModule,
    RouterModule,
    AokSvgIconComponent,
    AokTileModule,
  ],
})
export class AokArticleTeaserModule {}
