import { InjectionToken } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { ArrayBehaviorState, isAbsoluteURL, isString, normalizeUrl } from '@aok/common';

export const SITEMAP = new InjectionToken<AokSitemap>('SITEMAP');

export interface SitemapDescriptor {
  routes?: Routes;
  lazyChildren?: Record<string, SitemapDescriptor>;
  routeParamOptions?: Record<string, string[]>;
}

/** @internal */
interface NestedSitemapDescriptor extends SitemapDescriptor {
  baseUrl: string;
}

export interface SiteRef {
  readonly route: Route;
  readonly key: string;
  linkUrl: string;
  children?: SiteRef[];
  title?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  contract?: boolean;
}

function createSiteRefs(descriptor: SitemapDescriptor): SiteRef[] {
  const siteRefs: SiteRef[] = [];
  if (descriptor.routes != null) {
    for (const route of descriptor.routes) {
      const routeKey = route.data?.['sitemapKey'] || route.path;
      const routeDescriptor = descriptor.lazyChildren?.[routeKey];
      const routeTitle = route.data?.['title'] || route.data?.['sitemapTitle'];

      const hasEmptyRoutePath = !route.path && !route.children?.length;
      const hasWildcardPath = route.path?.includes('*');
      const isAllowed = route.data?.['sitemap'] !== false;

      if (!hasEmptyRoutePath && !hasWildcardPath && isAllowed) {
        const isParameterized = route.path?.includes(':');
        const routeParamOptions = descriptor.routeParamOptions?.[routeKey];
        const routes = (route?.children || []).concat(routeDescriptor?.routes || []);
        const resolveBaseUrl = (path): string => {
          const { baseUrl } = descriptor as NestedSitemapDescriptor;
          return baseUrl != null ? normalizeUrl('/', baseUrl, path) : normalizeUrl('/', path);
        };

        if (isParameterized && routeParamOptions != null) {
          for (const paramValue of routeParamOptions) {
            const baseUrl = resolveBaseUrl(paramValue);
            const title = typeof routeTitle === 'object' ? routeTitle[paramValue] : null;
            const children = createSiteRefs({
              ...routeDescriptor,
              baseUrl,
              routes,
            } as NestedSitemapDescriptor);

            siteRefs.push({ children, title, linkUrl: baseUrl, key: paramValue, route });
          }
        } else {
          let baseUrl;
          if (isAbsoluteURL(route.path)) {
            baseUrl = route.path;
          } else {
            baseUrl = resolveBaseUrl(route.path);
          }
          const title = isString(routeTitle) ? routeTitle : null;
          const children = createSiteRefs({
            ...routeDescriptor,
            baseUrl,
            routes,
          } as NestedSitemapDescriptor);

          if (routeKey)
            siteRefs.push({
              children,
              title,
              linkUrl: baseUrl,
              key: routeKey,
              target: route.data?.['target'],
              route,
              contract: route.data?.['contract'],
            });
          else siteRefs.push(...children);
        }
      }
    }
  }
  return siteRefs;
}

export class AokSitemap implements Iterable<SiteRef> {
  protected readonly state = new ArrayBehaviorState<SiteRef>();

  constructor(descriptor: SitemapDescriptor) {
    this.state.reset(...createSiteRefs(descriptor));
  }

  [Symbol.iterator](): Iterator<SiteRef> {
    return this.state.snapshot[Symbol.iterator]();
  }
}
