import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  A11yUtilsModule,
  AokKnownArticleCategoryRoute,
  AokRouteDataPipeModule,
  AokRouteParamPipeModule,
} from '@aok/common';
import {
  AokBreadcrumbsModule,
  AokFooterModule,
  AokToTopScrollerModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { helpResolver } from '../../resolvers/help.resolver';
import { AokNotAccessiblePage } from '../not-accessible/not-accessible.component';
import { HelpPage } from './help-page.component';

export const APP_HELP_ROUTES: Routes = [
  {
    path: '',
    component: AokNotAccessiblePage,
    canActivate: [() => false],
  },
  {
    path: ':category',
    component: HelpPage,
    resolve: {
      article: helpResolver,
    },
    data: {
      browserTitle: (d) => `${d.article?.title}`,
      title: {
        [AokKnownArticleCategoryRoute.GeneralInformation]: 'Allgemeine Informationen',
        [AokKnownArticleCategoryRoute.PortalUsage]: 'Nutzung des Arztportals',
      },
    },
  },
];

@NgModule({
  declarations: [HelpPage],
  exports: [HelpPage],
  imports: [
    RouterModule.forChild(APP_HELP_ROUTES),
    CommonModule,
    AokRouteDataPipeModule,
    A11yUtilsModule,
    AokRouteParamPipeModule,
    AokToTopScrollerModule,
    HeaderModule,
    AokFooterModule,
    AokBreadcrumbsModule,
    FeedbackButtonModule,
    InfoBannerComponent,
  ],
})
export class HelpModule {}
