<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline headline="Podcasts" hint="AOK Praxis-Talk  &nbsp;|&nbsp; AOK Niedersachsen">
</aok-cockpit-header-page-headline>

<div class="aok-container">
  <div class="podcast-teaser tw-col-1-12 lg:tw-col-1-13">
    <img src="assets/img/podcasts.png" alt="Podcast AOK Praxis-Talk" />
    <div class="podcast-content">
      <p class="top-text">
        Im „AOK Praxis-Talk“ – dem Podcast der AOK Niedersachsen für medizinisches Fachpersonal, Vertragsärztinnen und
        Vertragsärzte – dreht sich alles um die Arznei- und Heilmittelversorgung. Unsere Expertinnen und Experten
        stellen sich regelmäßig Ihren Fragen, antworten Ihnen ausführlich und machen damit Ihren Praxisalltag leichter.
        Also: Hören Sie doch einfach mal rein.
      </p>
      <p>{{ numberPodcasts }} Folgen</p>
      <button (click)="navigateToAokPodcasts()">
        Extern öffnen
        <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </button>
    </div>
  </div>

  <ng-container *ngIf="'podcasts' | aokRouteData | async as podcastList">
    <aok-podcast-teaser
      *ngFor="let podcast of podcastList.episodes"
      class="tw-col-1-12 lg:tw-col-1-13"
      [podcast]="podcast"
    >
    </aok-podcast-teaser>
    <aok-pagination
      *ngIf="numberPodcasts"
      class="tw-col-1-12 lg:tw-col-1-13"
      pageSize="10"
      routeQueryParam="page"
      [totalItems]="numberPodcasts"
    >
    </aok-pagination>
  </ng-container>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
