import { Pipe, PipeTransform } from '@angular/core';
import { AokAccountStatus } from '../../schemas';
import { getAccountStatusText } from '../../utils';

@Pipe({ name: 'aokAccountStatus', standalone: true })
export class AokAccountStatusPipe implements PipeTransform {
  transform(value: AokAccountStatus): string {
    return getAccountStatusText(value);
  }
}
