import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AokAccountStatus,
  AokDialogResult,
  AokDmpCaseManagementClient,
  AokMenuButtonEntry,
  AokToastService,
  AokUser,
  AokUserClient,
  ContextState,
  CurrentUserState,
  DialogOverlay,
  DMPCase,
  DmpCaseManagementCategory,
  isAssistant,
  isDoctor,
  KnownUserType,
  TableColumnDef,
} from '@aok/common';
import { AokShareDialog, AokSimpleDialog, FeedbackDmpCaseDialog } from '@aok/components';
import { KeycloakService } from 'keycloak-angular';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DmpCaseService } from './dmp-case.service';

@Injectable({
  providedIn: 'root',
})
export class DmpCaseManagementDetailViewService {
  constructor(
    private dmpCaseService: DmpCaseService,
    private dialog: DialogOverlay,
    private toastService: AokToastService,
    private route: ActivatedRoute,
    private contextState: ContextState,
    private currentUser: CurrentUserState,
    private userClient: AokUserClient,
    private keycloak: KeycloakService,
    private dmpCaseClient: AokDmpCaseManagementClient
  ) {}

  public getMenuOptions(dmpCategory: DmpCaseManagementCategory): AokMenuButtonEntry<DMPCase>[] {
    const menuOptions: AokMenuButtonEntry<DMPCase>[] = [];
    if (
      [
        DmpCaseManagementCategory.REGULAR,
        DmpCaseManagementCategory.INITIAL,
        DmpCaseManagementCategory.SUSPENSION,
      ].includes(dmpCategory)
    ) {
      menuOptions.push({
        id: 'markAsDone',
        title: this.isSuspension(dmpCategory) ? 'Ausblenden' : 'Als erledigt markieren',
        action: this.markAsDone.bind(this),
      });
    }

    if (environment?.enableSendDmpMessageToAOK) {
      menuOptions.push({
        id: 'leaveFeedback',
        title: 'Nachricht an AOK senden',
        action: this.leaveFeedback.bind(this),
      });
    }

    return menuOptions;
  }

  public getDisplayedColumns(dmpCategory: DmpCaseManagementCategory): TableColumnDef[] {
    return [
      {
        label: 'KV-Nr.',
        id: 'insuranceNumber',
        show: true,
      },
      {
        label: 'Name',
        id: 'lastName',
        sortBy: ['lastName', 'firstName'],
        show: true,
      },
      {
        label: 'Geburtstag',
        id: 'dateOfBirth',
        show: true,
      },
      {
        label: 'DMP',
        id: 'dmp',
        show: true,
      },
      {
        label: 'LANR',
        id: 'lanr',
        show: !isAssistant(this.currentUser.snapshot),
      },
      {
        label: 'Fallende',
        id: 'endDate',
        show: this.isSuspension(dmpCategory),
      },
      {
        label: 'Grund',
        id: 'endReason',
        show: this.isSuspension(dmpCategory),
      },
      {
        label: 'Fallbeginn',
        id: 'startDate',
        show: this.isEnrollment(dmpCategory),
      },
      {
        id: 'options',
        show: !this.isEnrollment(dmpCategory) || environment?.enableSendDmpMessageToAOK,
      },
    ];
  }

  public canShareDMPs(): Observable<boolean> {
    if (!isDoctor(this.currentUser.snapshot)) {
      return of(false);
    }

    return this.contextState.getOrg$().pipe(
      filter((org) => !!org),
      mergeMap((org) => {
        return this.currentUser.isLastDoctorAtBSNRLocation(org.bsnr);
      }),
      map((isLastDoctor) => !isLastDoctor)
    );
  }

  public getHasSharedDMPs(): Observable<boolean> {
    if (!isDoctor(this.currentUser.snapshot)) {
      return of(false);
    }

    return this.dmpCaseClient.checkSharedState(this.contextState.bsnr);
  }

  public openSharingDialog(): Observable<boolean> {
    return zip(this.getDoctorsForSharing(), this.dmpCaseClient.getSharedWithDoctors(this.contextState.bsnr)).pipe(
      mergeMap(([docs, userIdsWithAccess]) => {
        return this.displayShareDialog(docs, userIdsWithAccess);
      }),
      mergeMap((shared: number[]) => {
        if (shared) {
          return this.dmpCaseClient.share(this.contextState.bsnr, shared);
        }
        return EMPTY;
      }),
      mergeMap(() => {
        return this.getHasSharedDMPs();
      }),
      tap(() => {
        this.toastService.createSuccessToast(
          'Vorgang erfolgreich',
          'Die Freigaben Ihrer DMP-Fälle wurden aktualisiert.'
        );
      }),
      catchError(() => {
        this.toastService.createErrorToast(
          'Technischer Fehler',
          'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.'
        );
        return EMPTY;
      })
    );
  }

  public getLanrFilterOptions(dmpCategory: DmpCaseManagementCategory): Observable<AokUser[]> {
    return this.dmpCaseClient.getLanrOptions(dmpCategory);
  }

  private getDoctorsForSharing(): Observable<AokUser[]> {
    const email = this.keycloak.getKeycloakInstance()?.tokenParsed?.email;

    // Get all users that have access
    return this.userClient
      .list({
        bsnr: this.contextState.bsnr,
        userType: [KnownUserType.Doctor, KnownUserType.Kvn_Doctor, KnownUserType.Full_Kvn_Doctor],
        size: 1000,
        status: AokAccountStatus.Active,
      })
      .pipe(map((users) => users._embedded?.items?.filter((user) => user.email !== email)));
  }

  private displayShareDialog(docs: AokUser[], userIdsWithAccess: number[]): Observable<number[]> {
    return this.dialog.create(AokShareDialog, {
      closable: true,
      props: {
        userIdsWithAccess,
        docs,
        description: `<p class="tw-px-11">Legen Sie fest, wer auf Ihre DMP-Fälle zugreifen darf.</p`,
      },
    });
  }

  private isSuspension(dmpCategory: DmpCaseManagementCategory): boolean {
    return dmpCategory === DmpCaseManagementCategory.SUSPENSION;
  }

  private isEnrollment(dmpCategory: DmpCaseManagementCategory): boolean {
    return dmpCategory === DmpCaseManagementCategory.ENROLLMENT;
  }

  private markAsDone({ id, dmpCategory }: DMPCase): void {
    this.dialog
      .create(AokSimpleDialog, {
        props: {
          headerText: this.isSuspension(dmpCategory) ? 'DMP-Fall ausblenden' : 'DMP-Fall als erledigt markieren',
          confirmButtonText: this.isSuspension(dmpCategory) ? 'Ausblenden' : 'Als erledigt markieren',
          contentText: this.getContentText(dmpCategory),
          cancelButtonText: 'Abbrechen',
        },
      })
      .subscribe((result: AokDialogResult) => {
        if (result === AokDialogResult.CONFIRM) {
          this.dmpCaseService
            .markAsDone(id)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                this.markAsDoneErrorHandling(error);
                return EMPTY;
              })
            )
            .subscribe(() => {
              this.toastService.createSuccessToast('Vorgang erfolgreich', 'Der DMP-Fall wurde aus der Liste entfernt.');

              this.loadDmpCases(dmpCategory);
            });
        }
      });
  }

  private markAsDoneErrorHandling(error: HttpErrorResponse): void {
    let toastLabel = 'Bitte versuchen Sie es später erneut.';
    if (error.status === 403) {
      toastLabel = 'Sie haben keinen Zugriff mehr auf diesen Datensatz.';
    }
    this.toastService.createErrorToast('Vorgang fehlgeschlagen', toastLabel);
  }

  private leaveFeedback(dmpCase: DMPCase): void {
    this.dialog.create(FeedbackDmpCaseDialog, { props: { dmpCase } }).subscribe();
  }

  private loadDmpCases(dmpCategory: DmpCaseManagementCategory): void {
    const { page = 0, size, sort = '', query = '', dmp = '', doctorId = '' } = this.route.snapshot.queryParams;
    const pageParams = { page, size, sort, query, dmp, doctorId };

    this.dmpCaseService.loadDmpCases(dmpCategory, pageParams, this.contextState.practitionerLanr).subscribe();
  }

  private getContentText(dmpCategory: DmpCaseManagementCategory): string {
    switch (dmpCategory) {
      case DmpCaseManagementCategory.INITIAL:
        return `<p>Sind Sie sicher, dass Sie diesen DMP-Fall als erledigt markieren möchten? Wenn Sie auf "Als erledigt markieren" klicken, wird dieser DMP-Fall aus der Liste entfernt.</p>
                       <p>Falls die Dokumentation des Patienten auch im nächsten Quartal noch fehlt, erscheint er wieder in dieser Übersicht.</p>`;

      case DmpCaseManagementCategory.REGULAR:
        return `<p>Sind Sie sicher, dass Sie diesen DMP-Fall als erledigt markieren möchten? Wenn Sie auf "Als erledigt markieren" klicken, wird dieser DMP-Fall aus der Liste entfernt.</p>
                       <p>Falls der Patient im nächsten Quartal weiterhin am DMP-Programm teilnimmt, erscheint er wieder in dieser Übersicht.</p>`;

      case DmpCaseManagementCategory.SUSPENSION:
        return ` <p>Sind Sie sicher, dass Sie diesen DMP-Fall ausblenden möchten? Wenn Sie auf "DMP-Fall ausblenden" klicken, wird dieser DMP-Fall aus der Liste entfernt.</p>
                        <p>Falls der Patient in den letzten drei Monaten ausgeschrieben wurde, erscheint er im nächsten Quartal erneut in dieser Übersicht.</p>`;
    }
  }
}
