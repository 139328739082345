import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AokSitemapTreeComponent } from './sitemap-tree.component';
import { RouterLinkOrHrefModule } from '@aok/common';

const declarations = [AokSitemapTreeComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule, RouterLinkOrHrefModule],
})
export class AokSitemapTreeModule {}
