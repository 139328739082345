import { Pipe, PipeTransform } from '@angular/core';
import { AokUserRegistrationStatus } from '../../schemas';
import { getUserRegistrationStatusText } from '../../utils';

@Pipe({ name: 'aokRegistrationStatus', standalone: true })
export class AokRegistrationStatusIntlPipe implements PipeTransform {
  transform(value: AokUserRegistrationStatus | string): string {
    return getUserRegistrationStatusText(value);
  }
}
