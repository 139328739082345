import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AokBenefitSearchOptions, AokPage, AokSearchResult, AokSearchSuggestionsOptions } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

export const SEARCH_API_URL = new InjectionToken<string>('SEARCH_API_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/search',
});

@Injectable({ providedIn: 'root' })
export class AokSearchClient extends AokApiBaseClient {
  constructor(@Inject(SEARCH_API_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  list<T extends AokSearchResult = AokSearchResult>(options: AokBenefitSearchOptions): Observable<AokPage<T>> {
    return this.http.get<AokPage<T>>(this.resolveUrl(''), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  listSuggestions(options: AokSearchSuggestionsOptions): Observable<string[]> {
    return this.http.get<string[]>(this.resolveUrl('suggest'), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }
}
