import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  A11yUtilsModule,
  AokFullAddressPipe,
  AokPopoverComponent,
  AokPopoverContentTemplateComponent,
  AokSvgIconComponent,
  TrimControlDirective,
} from '@aok/common';
import {
  AokCheckboxModule,
  AokDropdownModule,
  AokFooterModule,
  AokHintModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  AokPaginationModule,
  AokRadioModule,
  FeedbackButtonModule,
  FormControlErrorModule,
  HeaderModule,
  InfoBannerComponent,
  RequiredMarkModule,
} from '@aok/components';
import { BillingSheetsPrintCollectionComponent } from './billing-sheets-print-collection.component';
import { BillingSheetsPrintingResultsComponent } from './billing-sheets-printing-results/billing-sheets-printing-results.component';
import { PrintCollectionTableComponent } from './print-collection-table/print-collection-table.component';

@NgModule({
  declarations: [
    BillingSheetsPrintCollectionComponent,
    BillingSheetsPrintingResultsComponent,
    PrintCollectionTableComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    ReactiveFormsModule,
    FeedbackButtonModule,
    AokFooterModule,
    AokSvgIconComponent,
    A11yUtilsModule,
    AokInputFieldModule,
    AokCheckboxModule,
    RequiredMarkModule,
    AokPopoverComponent,
    AokHintModule,
    AokNotificationBannerModule,
    FormControlErrorModule,
    AokRadioModule,
    CdkTableModule,
    AokPaginationModule,
    AokDropdownModule,
    TrimControlDirective,
    InfoBannerComponent,
    AokFullAddressPipe,
    AokPopoverContentTemplateComponent,
  ],
})
export class BillingSheetsPrintCollectionModule {}
