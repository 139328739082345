<table
  aria-label="Die Buttons in der Tabellenüberschrift dienen zum Sortieren der jeweiligen Spalte."
  aria-describedby="Berichte"
  class="aok reportsTable"
  cdk-table
  [dataSource]="reports?._embedded?.items"
>
  <ng-container cdkColumnDef="name">
    <th
      scope="col"
      cdk-header-cell
      aokFocusable
      *cdkHeaderCellDef
      class="clickable"
      (click)="sortBy('filename')"
      (keyup.enter)="sortBy('filename')"
    >
      <div
        [attr.aria-sort]="setAriaSort(isSortedBy('filename'), sortDirection)"
        role="button"
        class="flex"
        [ngClass]="{ 'sort-active': isSortedBy('filename') }"
      >
        Name
        <ng-container *ngIf="isSortedBy('filename')">
          <aok-svg-icon
            [size]="16"
            [name]="sortDirection === 'asc' ? 'arrow-rounded_up' : 'arrow-rounded_down'"
          ></aok-svg-icon>
        </ng-container>
      </div>
    </th>
    <td class="column-fileName" cdk-cell *cdkCellDef="let report">
      <p
        class="clickable report-selector"
        [ngClass]="{ 'aok-bold': !readAtRuntime[report.id] && !report.readAt }"
        aokFocusable
        (click)="openReport(report)"
        (keyup.enter)="openReport(report)"
      >
        {{ report.fileName | aokRemoveFileExtension : ['pdf'] | aokRemoveDateType }}
      </p>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="sharedIcon">
    <th cdk-header-cell *cdkHeaderCellDef></th>
    <td class="column-categorie memberIcon" cdk-cell *cdkCellDef="let report">
      <aok-svg-icon
        *ngIf="showSharedIcon(report); else noIcon"
        id="shared-icon"
        title="Freigegebener Bericht"
        name="shared-report"
        size="16px"
      >
      </aok-svg-icon>
      <ng-template #noIcon><hr /></ng-template>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="ownerName">
    <th
      scope="col"
      cdk-header-cell
      aokFocusable
      *cdkHeaderCellDef
      class="clickable"
      (click)="sortBy('ownerName')"
      (keyup.enter)="sortBy('ownerName')"
    >
      <div
        [attr.aria-sort]="setAriaSort(isSortedBy('ownerName'), sortDirection)"
        role="button"
        class="flex no-wrap"
        [ngClass]="{ 'sort-active': isSortedBy('ownerName') }"
      >
        Eigentümer
        <ng-container *ngIf="isSortedBy('ownerName')">
          <aok-svg-icon
            [size]="16"
            [name]="sortDirection === 'asc' ? 'arrow-rounded_up' : 'arrow-rounded_down'"
          ></aok-svg-icon>
        </ng-container>
      </div>
    </th>
    <td class="column-categorie" cdk-cell *cdkCellDef="let report">
      <p *ngIf="activeUser.snapshot.id === report.ownerId; else ownerName">Sie</p>
      <ng-template #ownerName>
        <p>{{ report.ownerTitle }} {{ report.ownerFirstName }} {{ report.ownerLastName }}</p>
      </ng-template>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="date">
    <th
      scope="col"
      cdk-header-cell
      aokFocusable
      *cdkHeaderCellDef
      class="clickable"
      (click)="sortBy('lastModifiedDate')"
      (keyup.enter)="sortBy('lastModifiedDate')"
    >
      <div
        [attr.aria-sort]="setAriaSort(isSortedBy('lastModifiedDate'), sortDirection)"
        role="button"
        class="flex no-wrap"
        [ngClass]="{ 'sort-active': isSortedBy('lastModifiedDate') }"
      >
        Datum
        <ng-container *ngIf="isSortedBy('lastModifiedDate')">
          <aok-svg-icon
            [size]="16"
            [name]="sortDirection === 'asc' ? 'arrow-rounded_up' : 'arrow-rounded_down'"
          ></aok-svg-icon>
        </ng-container>
      </div>
    </th>
    <td class="column-categorie" cdk-cell *cdkCellDef="let report">
      <p>
        {{ report.lastModifiedDate | date : 'dd.LL.yyyy' }}
      </p>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="options">
    <th scope="col" cdk-header-cell *cdkHeaderCellDef>Optionen</th>
    <td class="align-right column-actions" cdk-cell *cdkCellDef="let report">
      <aok-menu-button [entries]="getMenuOptionsPerReport(report)" (selected)="$event['action'](report)">
        Optionen
      </aok-menu-button>
    </td></ng-container
  >

  <tr cdk-header-row *cdkHeaderRowDef="displayColumns"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: displayColumns"></tr>
</table>

<div class="pagination" *ngIf="reports?.page?.totalPages > 1">
  <aok-pagination
    [activePage]="$any(reports.page.number)"
    [totalItems]="reports.page.totalElements"
    [pageSize]="reports.page.size"
    routeQueryParam="page"
  >
  </aok-pagination>
</div>
