import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { A11yUtilsModule, AokFullNamePipeModule, AokRouteDataPipeModule, AokSvgIconComponent } from '@aok/common';
import {
  AokArticleTeaserModule,
  AokCardModule,
  AokFooterModule,
  AokPaginationModule,
  AokReminderCarouselModule,
  AokTileModule,
  ArticleTeaserListModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { dashboardArticleTeasersResolver } from '../../resolvers/dashboard-article-teasers.resolver';
import { dashboardRemindersResolver } from '../../resolvers/dashboard-reminders.resolver';
import { DashboardComponent } from './dashboard.component';

export const APP_DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    component: DashboardComponent,
    resolve: {
      articleTeasers: dashboardArticleTeasersResolver,
      reminders: dashboardRemindersResolver,
    },
  },
];

@NgModule({
  declarations: [DashboardComponent],
  exports: [DashboardComponent],
  imports: [
    RouterModule.forChild(APP_DASHBOARD_ROUTES),
    CommonModule,
    AokArticleTeaserModule,
    AokRouteDataPipeModule,
    AokFullNamePipeModule,
    AokPaginationModule,
    AokReminderCarouselModule,
    A11yUtilsModule,
    AokSvgIconComponent,
    HeaderModule,
    AokCardModule,
    ArticleTeaserListModule,
    AokFooterModule,
    AokTileModule,
    FeedbackButtonModule,
    InfoBannerComponent,
  ],
})
export class AppDashboardModule {}
