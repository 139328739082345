import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  A11yUtilsModule,
  AokAccountStatusPipe,
  AokDoctorTypeIntlPipe,
  AokFullNamePipeModule,
  AokPopoverComponent,
  AokRegistrationStatusIntlPipe,
  AokRouteDataPipeModule,
  AokSvgIconComponent,
  DialogOverlayModule,
} from '@aok/common';
import {
  AddPracticesDialog,
  AokAccordionOptionModule,
  AokCockpitAddBSNRDialogModule,
  AokCockpitChangeEmailDialogModule,
  AokDialogLayoutModule,
  AokFgsModule,
  AokFooterModule,
  AokMenuButtonModule,
  AokMfaDialogModule,
  AokMfaRequestDialogModule,
  AokNotificationBannerModule,
  AokPaginationModule,
  AokSimpleDialogModule,
  AokStatusDotModule,
  AokTableModule,
  AokTabsModule,
  DeleteKvnProfileDialogModule,
  FeedbackButtonModule,
  HeaderModule,
  InfoBannerComponent,
} from '@aok/components';
import { notKvnPM } from '../../guards/not-kvn-pm.guard';
import { practiceManagementGuard } from '../../guards/practice-management.guard';
import { allowDirectRegistrationResolver } from '../../resolvers/allow-direct-registration.resolver';
import { currentUserResolver } from '../../resolvers/current-user.resolver';
import { PracticeDataComponent } from './practice-data/practice-data.component';
import { PracticeManagementComponent } from './practice-management/practice-management.component';
import { ProfileDataComponent } from './profile-data/profile-data.component';
import { ProfileComponent } from './profile.component';

export const APP_PROFILE_ROUTES: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'praxisverwaltung',
        runGuardsAndResolvers: 'always',
        component: PracticeManagementComponent,
        canActivate: [practiceManagementGuard],
        data: {
          title: 'Praxisverwaltung',
          breadcrumb: false,
        },
      },
      {
        path: 'praxisdaten',
        runGuardsAndResolvers: 'always',
        component: PracticeDataComponent,
        data: {
          title: 'Praxisdaten',
          breadcrumb: false,
        },
      },
      {
        path: 'profildaten',
        runGuardsAndResolvers: 'always',
        component: ProfileDataComponent,
        canActivate: [notKvnPM],
        resolve: {
          user: currentUserResolver,
          allowDirectRegistration: allowDirectRegistrationResolver,
        },
        data: {
          title: 'Profildaten',
          breadcrumb: false,
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'praxisverwaltung',
      },
    ],
  },
];

@NgModule({
  declarations: [PracticeManagementComponent, ProfileComponent, PracticeDataComponent, ProfileDataComponent],
  exports: [ProfileComponent],
  imports: [
    AokAccordionOptionModule,
    RouterModule.forChild(APP_PROFILE_ROUTES),
    CommonModule,
    CdkTableModule,
    AokStatusDotModule,
    AokRouteDataPipeModule,
    AokFullNamePipeModule,
    AokPaginationModule,
    AokSimpleDialogModule,
    AokMfaDialogModule,
    AokMenuButtonModule,
    AokSvgIconComponent,
    AokFgsModule,
    AokTabsModule,
    HeaderModule,
    AokFooterModule,
    AokTableModule,
    DialogOverlayModule,
    AokDialogLayoutModule,
    AokCockpitAddBSNRDialogModule,
    AokMfaRequestDialogModule,
    AokCockpitChangeEmailDialogModule,
    FeedbackButtonModule,
    AokPopoverComponent,
    A11yUtilsModule,
    AokNotificationBannerModule,
    DeleteKvnProfileDialogModule,
    InfoBannerComponent,
    AddPracticesDialog,
  ],
  providers: [AokRegistrationStatusIntlPipe, AokAccountStatusPipe, AokDoctorTypeIntlPipe],
})
export class ProfileModule {}
