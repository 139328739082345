import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  calculateSumOfObjectProperties,
  ContextState,
  CurrentUserState,
  DmpCaseManagementCategory,
  DMPCaseManagementCounts,
  DmpOverviewData,
  getCurrentQuarter,
  isDoctor,
} from '@aok/common';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DMP_CM_CATEGORY_LABELS, DMP_CM_URLS } from '../../../../config/dmp-case-management.config';
import { DmpCaseManagementOverviewService } from '../../../../services/dmp-case-management-overview.service';

export interface DMPOverviewCardData {
  /** lower case {@link DmpCaseManagementCategory} */
  category: string;
  linkText: string;
  icon: string;
  content?: string;
  columns: string;
  hasTextHeadline?: boolean;
}

@Component({
  selector: 'aok-cockpit-dmp-case-management-overview',
  templateUrl: './dmp-case-management-overview.component.html',
  styleUrls: ['./dmp-case-management-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DmpCaseManagementOverviewComponent implements OnInit, OnDestroy {
  public readonly headerHint = 'Diese Übersicht wird wöchentlich aktualisiert. Letzte Aktualisierung am: ';
  public readonly categoryLabels = DMP_CM_CATEGORY_LABELS;
  public readonly categoryUrls = DMP_CM_URLS;
  public readonly currentQuarter = getCurrentQuarter();
  public readonly currentYear = new Date().getFullYear();
  public readonly cards: DMPOverviewCardData[] = [
    {
      category: DmpCaseManagementCategory.REGULAR.toLowerCase(),
      linkText: 'Ausstehende Dokumentationen',
      icon: '0184-files',
      content: 'im aktuellen Quartal',
      columns: 'tw-col-2-7',
      hasTextHeadline: true,
    },
    {
      category: DmpCaseManagementCategory.INITIAL.toLowerCase(),
      linkText: 'Fehlende Erstdokumentationen',
      icon: '0210-register',
      columns: 'tw-col-7-12',
      hasTextHeadline: true,
    },
    {
      category: DmpCaseManagementCategory.SUSPENSION.toLowerCase(),
      linkText: 'Ausschreibungen',
      icon: '0180-file_minus',
      content: 'innerhalb der letzten drei Monate',
      columns: 'tw-col-2-7',
    },
    {
      category: DmpCaseManagementCategory.ENROLLMENT.toLowerCase(),
      linkText: 'Alle Einschreibungen',
      icon: '0181-file_add',
      columns: 'tw-col-7-12',
    },
  ];

  public contextOptions$ = this.contextState.getContextSelectorOptions$();

  public overviewData: DmpOverviewData;
  public hasDmpCases: boolean;

  private ngDestroyed = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dmpOverviewService: DmpCaseManagementOverviewService,
    private currentUser: CurrentUserState,
    private contextState: ContextState
  ) {
    this.route.data
      .pipe(
        takeUntil(this.ngDestroyed),
        map((data: Data) => data?.overviewData)
      )
      .subscribe((overviewData) => {
        this.checkIfHasDmpCounts(overviewData?.counts);
        this.overviewData = overviewData;
      });
  }

  get isDoctor(): boolean {
    return isDoctor(this.currentUser.snapshot);
  }

  ngOnInit(): void {
    this.updateDataOnContextChange();
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  public navigateToDmpCategory(category: DmpCaseManagementCategory): void {
    this.router.navigate([this.categoryUrls[category]], { relativeTo: this.route });
  }

  private updateDataOnContextChange(): void {
    this.dmpOverviewService
      .getDmpPageOverviewDataOnContextChange()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe((data) => {
        this.checkIfHasDmpCounts(data?.counts);
        this.overviewData = data;
      });
  }

  private checkIfHasDmpCounts(data: DMPCaseManagementCounts): void {
    this.hasDmpCases = calculateSumOfObjectProperties(data) >= 1;
  }
}
