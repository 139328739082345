import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AokFullNamePipe,
  AokMenuButtonEntry,
  AokPage,
  AokUser,
  DMPCase,
  DmpCaseManagementCategory,
  DropdownSchema,
  rotate,
  scrollToTop,
  TableColumnDef,
} from '@aok/common';
import { DropdownMode, getAriaSort } from '@aok/components';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  diagnoseFilterOptions,
  DMP_CM_CATEGORY_LABELS,
  DMP_CM_CATEGORY_ORDER,
  DMP_CM_URLS,
} from '../../../../config/dmp-case-management.config';
import { DmpCaseManagementDetailViewService } from '../../../../services/dmp-case-management-detail-view.service';
import { DmpCaseService } from '../../../../services/dmp-case.service';
import { DmpCaseState } from '../../../../states/dmp-case.state';

@Component({
  selector: 'aok-cockpit-dmp-case-management-detail-view',
  templateUrl: './dmp-case-management-detail-view.component.html',
  styleUrls: ['./dmp-case-management-detail-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DmpCaseManagementDetailViewComponent implements OnInit {
  public readonly categoriesOrder = DMP_CM_CATEGORY_ORDER;
  public readonly categoryLabels = DMP_CM_CATEGORY_LABELS;
  public readonly categoryUrls = DMP_CM_URLS;
  public readonly dmpCaseCategory = DmpCaseManagementCategory;

  public dmpCategory: DmpCaseManagementCategory;
  public dmpCases: AokPage<DMPCase>;

  public recordsMapping: { [k: string]: string } = { '=1': '# Datensatz', other: '# Datensätze' };

  // TODO extract sorting logic from this component
  public sortColumns: string[];
  // TODO (for future) support multiple sort direction, one per each sort column
  public sortDirection = '';

  public searchString = '';

  public menuOptions: AokMenuButtonEntry<DMPCase>[] = [];
  public displayColumns: TableColumnDef[] = [];

  public dropdownMode = DropdownMode.SIMPLE;
  public filterForm = this.setFilterFormGroup();
  public isFiltering$ = new ReplaySubject<boolean>(1);
  public showFilteringSection = new Observable<boolean>();
  public diagnoseFilterOptions = diagnoseFilterOptions;
  public lanrFilterOptions: DropdownSchema[] = [];
  public isOpenFilterArea = false;
  public filterCounter = 0;
  public setAriaSort = getAriaSort;
  public canShareDMPs: Observable<boolean>;
  public hasSharedDMPs: boolean;
  public hasLanrFilter: boolean;

  public get getDisplayedColumnIds(): string[] {
    return this.getDisplayedColumns?.map((column) => column?.id);
  }

  public get getDisplayedColumns(): TableColumnDef[] {
    return this.displayColumns?.filter((column) => column?.show);
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private dmpCaseState: DmpCaseState,
    private dmpCaseService: DmpCaseService,
    private dmpCaseDetailService: DmpCaseManagementDetailViewService,
    private cd: ChangeDetectorRef,
    private namePipe: AokFullNamePipe
  ) {
    this.handleRouteDataChange();
    this.handleQueryParams();
    this.handleDMPSharing();
    this.setDmpCases();
    this.handleContextChange();
    this.handleFilterChanges();
    this.handleDisplayOfFilteringSection();
    scrollToTop();
  }

  ngOnInit(): void {}

  public resetSearchAndFilters(): void {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { query: null, dmp: null, doctorId: null },
      queryParamsHandling: 'merge',
    });
  }

  public resetFilters(): void {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { query: queryParams.query, dmp: null, doctorId: null },
      queryParamsHandling: 'merge',
    });
  }

  public search(searchString: string): void {
    const pageParam = { page: 0 };

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: searchString?.length === 0 ? { query: null, ...pageParam } : { query: searchString, ...pageParam },
      queryParamsHandling: 'merge',
    });
  }

  public isSortedBy(sortBy: string[]): boolean {
    return this.sortDirection && this.isSameSort(sortBy);
  }

  public sortBy(sortBy: string[]): void {
    this.sortDirection = this.isSameSort(sortBy) ? rotate[this.sortDirection] : 'asc';
    this.sortColumns = [...sortBy];

    const sort = this.sortDirection ? this.buildSortParameterValue(sortBy) : [];

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { sort },
      queryParamsHandling: 'merge',
    });
  }

  public isOptions(column: string): boolean {
    return column === 'options';
  }

  public isEndReason(column: string): boolean {
    return column === 'endReason';
  }

  public isName(column: string): boolean {
    return column === 'lastName';
  }

  public isSimpleLabel(column: string): boolean {
    return ['insuranceNumber', 'dmp', 'lanr', 'endReason'].includes(column);
  }

  public isValueDateType(value: string): boolean {
    return ['startDate', 'endDate', 'dateOfBirth'].includes(value);
  }

  public navigateToCategory(category: DmpCaseManagementCategory): void {
    this.router.navigate(['/services/dmp-falluebersicht', this.categoryUrls[category.toLowerCase()]]);
  }

  public openSharingDialog(): void {
    this.dmpCaseDetailService.openSharingDialog().subscribe((shared) => {
      this.hasSharedDMPs = shared;
      this.cd.markForCheck();
    });
  }

  private handleDisplayOfFilteringSection(): void {
    this.showFilteringSection = this.isFiltering$.asObservable().pipe(
      takeUntilDestroyed(),
      map((isFiltering) => isFiltering || this.dmpCases?._embedded?.items?.length > 0)
    );
  }

  private removeQueryParam(paramName: string): void {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...queryParams, [paramName]: null },
      queryParamsHandling: 'merge',
    });
  }

  protected getColumnSortBy(column: TableColumnDef): string[] {
    return column?.sortBy || [column?.id];
  }

  private isSameSort(columns: string[]): boolean {
    return this.sortColumns?.every((column, index) => {
      return column === columns[index];
    });
  }

  private buildSortParameterValue(columns: string[]): string[] {
    return columns.map((column) => `${column},${this.sortDirection}`);
  }

  private setFilterFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dmp: new UntypedFormControl(),
      doctorId: new UntypedFormControl(),
    });
  }

  private setDmpCases(): void {
    this.dmpCaseState
      .asObservable()
      .pipe(takeUntilDestroyed())
      .subscribe((dmpCases) => {
        this.dmpCases = dmpCases;
        this.cd.markForCheck();
      });
  }

  private handleRouteDataChange(): void {
    this.route.data.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.setLanrFilterOptions(data.lanrFilterOptions);

      if (this.dmpCategory === data.dmpCategory) {
        scrollToTop();
        return;
      }
      // update defaults only when dmp category changes
      this.dmpCategory = data.dmpCategory;

      this.displayColumns = this.dmpCaseDetailService.getDisplayedColumns(this.dmpCategory);
      this.menuOptions = this.dmpCaseDetailService.getMenuOptions(this.dmpCategory);
      this.setDefaultSort();

      this.cd.markForCheck();
    });
  }

  private handleQueryParams(): void {
    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.filterForm.get('dmp').patchValue(params?.dmp || this.diagnoseFilterOptions[0].value, { emitEvent: false });

      let hasFiltering = params?.dmp || params?.query;
      this.searchString = params?.query;

      if (this.hasLanrFilter) {
        this.handleDoctorIdParam(params);
        this.setFilterCounter();
        hasFiltering ||= params?.doctorId;
      } else if (params?.doctorId) {
        this.removeQueryParam('doctorId');
      }

      this.isFiltering$.next(!!hasFiltering);
      this.cd.markForCheck();
    });
  }

  private handleContextChange(): void {
    this.dmpCaseService.updateTableOnContextChange(this.dmpCategory).pipe(takeUntilDestroyed()).subscribe();
  }

  private handleFilterChanges(): void {
    this.filterForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(({ dmp, doctorId }) => {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {
          dmp: dmp === 'Alle' ? null : dmp,
          doctorId: doctorId === 'Alle' ? null : doctorId,
          page: 0,
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  private handleDMPSharing(): void {
    this.canShareDMPs = this.dmpCaseDetailService.canShareDMPs();

    this.dmpCaseDetailService.getHasSharedDMPs().subscribe((shared) => {
      this.hasSharedDMPs = shared;
      this.cd.markForCheck();
    });
  }

  private setDefaultSort(): void {
    const sort: string[] = this.route.snapshot.queryParamMap?.getAll('sort');

    if (!sort || sort.length === 0) {
      this.sortDirection = null;
      return;
    }

    this.sortDirection = sort[0]?.split?.(',')[1];
    this.sortColumns = sort.map((sortValue) => {
      // sort value has format 'column,order'
      return sortValue?.split?.(',')[0];
    });
  }

  private setLanrFilterOptions(users: AokUser[]): void {
    if (users?.length > 1) {
      const options = [{ label: 'Alle LANR anzeigen', value: 'Alle' }] as DropdownSchema[];
      users.forEach((user) => {
        options.push(this.buildLanrFilterOption(user));
      });
      this.hasLanrFilter = true;
      this.lanrFilterOptions = options;
    } else {
      this.hasLanrFilter = false;
    }
  }

  private buildLanrFilterOption(user: AokUser): DropdownSchema {
    return {
      label: `${user.practitionerResource?.lanr} - ${this.namePipe.transform(user, 'TT FF LL')}`,
      value: user.id.toString(),
    };
  }

  private handleDoctorIdParam(params: Params): void {
    let selectedValue = this.lanrFilterOptions[0].value;
    if (params?.doctorId) {
      const matchingLanrOption = this.lanrFilterOptions.find((option) => option.value === params.doctorId.toString());
      if (matchingLanrOption) {
        selectedValue = matchingLanrOption.value;
      } else {
        this.removeQueryParam('doctorId');
      }
    }
    this.filterForm.get('doctorId').patchValue(selectedValue, { emitEvent: false });
  }

  private setFilterCounter(): void {
    this.filterCounter = Object.values(this.filterForm.controls).reduce((total, formControl) => {
      return formControl.value !== 'Alle' ? total + 1 : total;
    }, 0);
  }
}
