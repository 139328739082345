import { Component, ViewEncapsulation } from '@angular/core';
import { AokPodcastClient } from '@aok/common';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'aok-cockpit-podcasts',
  styleUrls: ['./podcasts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './podcasts.component.html',
})
export class PodcastsComponent {
  private _numberPodcasts = new BehaviorSubject<number>(null);

  constructor(client: AokPodcastClient) {
    client.list({ page_size: 50, offset: 0 }).subscribe((podcasts) => {
      this._numberPodcasts.next(podcasts.episodes.length);
    });
  }

  get numberPodcasts(): number {
    return this._numberPodcasts.getValue();
  }

  public navigateToAokPodcasts(): void {
    window.open(AppConfig.aokPodcastsLink, '_blank');
  }
}
