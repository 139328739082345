import {
  Attribute,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { isAbsoluteURL, isString } from '../../utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLinkOrHref]',
  providers: [{ provide: RouterLink, useExisting: RouterLinkOrHrefDirective }],
})
export class RouterLinkOrHrefDirective extends RouterLink {
  @Input() target = '_blank';
  private _href: string | null;
  private isRouterLink = false;

  constructor(
    // due to the unfortunate fact, that the `RouterLink` does declare the properties "router" and
    // "route" privately, we need to given them another name here...
    protected router2: Router,
    protected route2: ActivatedRoute,
    renderer: Renderer2,
    protected element: ElementRef,
    protected sanitizer: DomSanitizer,
    @Inject(WINDOW) protected window: /* @dynamic */ Window,
    @Attribute('tab-index') tabIndex: string
  ) {
    super(router2, route2, tabIndex, renderer, element);
  }

  @Input() set routerLinkOrHref(value: any[] | string | undefined) {
    if (isString(value) && isAbsoluteURL(value)) {
      this._href = this.sanitizer.sanitize(SecurityContext.URL, value);
      this.resetRouterLink();
    } else {
      this.isRouterLink = true;
      if (value != null) this.routerLink = value;
      else this.resetRouterLink();
      this._href = null;
    }
  }

  get isHref(): boolean {
    return this._href != null;
  }

  @HostListener('keyup', ['$event']) keyupEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.isHref) {
        this.window.open(this._href, this.target);
      } else if (this.isRouterLink) {
        super.onClick(0, false, false, false, false);
      }
    }
  }

  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean): boolean {
    if (this.isHref) {
      this.window.open(this._href, this.target);
      return true;
    } else if (this.isRouterLink) {
      return super.onClick(button, ctrlKey, shiftKey, altKey, metaKey);
    } else {
      return false;
    }
  }

  protected resetRouterLink(): void {
    this.isRouterLink = false;
    // workaround, forcing routerLinkActive directives to not react on this instance anymore,
    // since "ꝋ" is not a valid url character, we should actually never be able to active such
    // an instance
    this.routerLink = 'ꝋ';
  }
}
