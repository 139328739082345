import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { A11yUtilsModule, AokSvgIconComponent, RouterLinkOrHrefModule } from '@aok/common';
import { AokTileComponent } from './tile.component';

const declarations = [AokTileComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule, AokSvgIconComponent, A11yUtilsModule, RouterLinkOrHrefModule, RouterLink],
})
export class AokTileModule {}
