import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AokPodcast } from '@aok/common';
import { TileSchema } from '../../schema';

@Component({
  selector: 'aok-podcast-teaser',
  styleUrls: ['./podcast-teaser.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <aok-tile [tile]="getTileFromPodcast()">
    <div class="article-preview-header" metaData>
      <div class="metadata aok-text-l1">
        <span>{{ podcast.published_at | date : 'dd.MM.yyyy' }}</span>
        <span>{{ podcast.duration * 1000 | date : 'mm:ss' }} Min</span>
      </div>
    </div>
    <div class="content" content>
      <aok-article-content [value]="podcast.description" tease="true"></aok-article-content>
      <button class="secondary" (click)="navigateToAokPodcast()">
        Extern abspielen
        <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </button>
    </div>
  </aok-tile>`,
})
export class AokPodcastTeaserComponent {
  @Input() podcast: AokPodcast;

  public getTileFromPodcast(): TileSchema {
    return {
      link: this.podcast.url,
      title: this.podcast.title,
      hidePlaceholder: true,
    };
  }

  navigateToAokPodcast(): void {
    window.open(this.podcast.url, '_blank');
  }
}
