<aok-cockpit-header></aok-cockpit-header>
<aok-info-banner></aok-info-banner>
<aok-cockpit-header-page-headline [headline]="pageTitle"></aok-cockpit-header-page-headline>

<ng-container *ngIf="reminders">
  <aok-reminder-carousel
    class="aok-bg-sand"
    *ngIf="reminders?.length"
    [reminders]="reminders"
    (showAll)="openNotificationSidebar()"
    (actionLinkClicked)="handleActionLinkClick($event)"
  ></aok-reminder-carousel>
</ng-container>

<div>
  <div class="aok-bg-pastel-blue-1">
    <div class="aok-container">
      <h4 class="tw-text-green">Schnellzugriff</h4>
    </div>
    <div class="aok-container">
      <div class="access-card-container">
        <aok-card
          tabindex="-1"
          *ngFor="let shortcut of shortcuts"
          [label]="shortcut?.label"
          [iconName]="shortcut?.iconName"
          [link]="shortcut?.link"
          [fn]="shortcut?.fn"
        >
        </aok-card>
      </div>
    </div>
  </div>
</div>

<div class="aok-container">
  <h3 class="article-title">Aktuelle Artikel</h3>
</div>

<ng-container *ngIf="'articleTeasers' | aokRouteData | async as articleTeasers">
  <div *ngIf="articleTeasers" class="articleTeaserContainer">
    <aok-article-teaser-list [articles]="articleTeasers"></aok-article-teaser-list>
  </div>
</ng-container>

<div class="aok-container">
  <aok-tile class="podcast horizontal reverse tw-col-1-12 lg:tw-col-1-13" [tile]="podcastTile">
    <div content>
      <p>
        Im „AOK Praxis-Talk“ – dem Podcast der AOK Niedersachsen für medizinisches Fachpersonal, Vertragsärztinnen und
        Vertragsärzte – dreht sich alles um die Arznei- und Heilmittelversorgung. Unsere Expertinnen und Experten
        stellen sich regelmäßig Ihren Fragen, antworten Ihnen ausführlich und machen damit Ihren Praxisalltag leichter.
        Also: Hören Sie doch einfach mal rein.
      </p>
    </div>
  </aok-tile>
</div>

<aok-feedback-button></aok-feedback-button>
<aok-cockpit-footer-wrapper></aok-cockpit-footer-wrapper>
