import {
  AokContractType,
  AokUserRegistrationStatus,
  DoctorType,
  KnownUserGender,
  KnownUserSalutation,
  KnownUserType,
} from '../../schemas';

export const getGenderText = (gender: KnownUserGender | string): string => {
  switch (gender) {
    case KnownUserGender.Diverse:
      return 'divers';
    case KnownUserGender.Female:
      return 'weiblich';
    case KnownUserGender.Male:
      return 'männlich';
    default:
      return gender || '';
  }
};

export const getSalutationText = (salutation: KnownUserSalutation | string): string => {
  switch (salutation) {
    case KnownUserSalutation.Mr:
      return 'Herr';
    case KnownUserSalutation.Mrs:
      return 'Frau';
    case KnownUserSalutation.None:
      return 'Keine';
    default:
      return salutation || '';
  }
};

export const getUserTypeText = (userType: KnownUserType | string, abbreviated?: boolean): string => {
  switch (userType) {
    case KnownUserType.Doctor:
      return 'Arzt';
    case KnownUserType.Kvn_Doctor:
      return 'KVN-Arzt';
    case KnownUserType.Full_Kvn_Doctor:
      return 'Arzt, KVN-Arzt';
    case KnownUserType.Assistant:
      return abbreviated ? 'PM' : 'Praxismitarbeiter';
    case KnownUserType.Employee:
      return 'AOK Mitarbeiter';
    case KnownUserType.Kvn_Practice_Team:
      return 'KVN-Praxisteam';
    default:
      return userType || '';
  }
};
export const getUserContractText = (contractType: AokContractType | string): string => {
  switch (contractType) {
    case AokContractType.Hzv && true.toString():
      return 'HzV';
    case false.toString():
      return '-';
    default:
      return contractType || '';
  }
};

export const getUserRegistrationStatusText = (registrationStatus: AokUserRegistrationStatus | string): string => {
  switch (registrationStatus) {
    case AokUserRegistrationStatus.Approved:
      return 'Bearbeitung abgeschlossen';
    case AokUserRegistrationStatus.Pending:
      return 'Zur Prüfung';
    case AokUserRegistrationStatus.Rejected:
      return 'Abgelehnt';
    case AokUserRegistrationStatus.PinExpired:
      return 'PIN abgelaufen';
    case AokUserRegistrationStatus.PinMarked:
      return 'PIN vorgemerkt für Briefversand';
    case AokUserRegistrationStatus.PinInLetterDispatch:
      return 'PIN in Briefversand';
    case AokUserRegistrationStatus.CardCreation:
      return 'Displaykarte wird erstellt';
    case AokUserRegistrationStatus.FailedExport:
      return 'PIN vorgemerkt für Briefversand';
    case AokUserRegistrationStatus.Invited:
      return 'eingeladen';
    case AokUserRegistrationStatus.RequestReceived:
      return 'Anfrage eingegangen';
    case AokUserRegistrationStatus.InternalReview:
      return 'Interne Prüfung';
    default:
      return registrationStatus || '';
  }
};

export const getDoctorType = (doctorType: DoctorType | string): string => {
  switch (doctorType) {
    case DoctorType.OWNER:
      return 'Vertragsarzt';
    case DoctorType.EMPLOYED:
      return 'angestellter Arzt';
    default:
      return doctorType || '';
  }
};
