import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DialogOverlay } from '@aok/common';
import { CompletionSupportDialog } from '@aok/components';
import { AppConfig } from '../../../config/app.config';

@Component({
  selector: 'aok-cockpit-handouts',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './handouts.component.html',
  styleUrls: ['./handouts.component.scss'],
})
export class HandoutsComponent {
  constructor(private dialog: DialogOverlay) {}

  public navigateToHandoutsAokPage(): void {
    window.open(AppConfig.aokHandoutsPage, '_blank');
  }

  public openCompletionSupportDialog(): void {
    this.dialog.create(CompletionSupportDialog, {}).subscribe();
  }
}
