import { Component, Inject, Input, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContextState, getContextSpecificUrl, getKvnStateUrl } from '@aok/common';
import { AokSitemap, SITEMAP, SiteRef } from './sitemap';
import { AokSitemapIntlService } from './sitemap-intl.service';
import { KvnState } from '../../../states';

@Component({
  selector: 'aok-sitemap-tree',
  styleUrls: ['./sitemap-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-template #siteLiTemplate let-siteRef let-isChild="isChild">
      <li *ngIf="resolveTitle(siteRef)">
        <a
          class="aok-link-1"
          [ngClass]="{ child: isChild }"
          [target]="siteRef.target ?? '_self'"
          [routerLinkOrHref]="siteRef.contract ? interpolateContext(siteRef) : siteRef.linkUrl"
          >{{ resolveTitle(siteRef) }}</a
        >
        <ul *ngIf="siteRef.children?.length > 0">
          <ng-container *ngFor="let childSiteRef of filterChildren(siteRef.children)">
            <ng-container
              *ngTemplateOutlet="siteLiTemplate; context: { $implicit: childSiteRef, isChild: true }"
            ></ng-container>
          </ng-container>
        </ul>
      </li>
    </ng-template>

    <ul>
      <ng-container *ngFor="let siteRef of sitemap">
        <ng-container *ngTemplateOutlet="siteLiTemplate; context: { $implicit: siteRef }"></ng-container>
      </ng-container>
    </ul>
  `,
})
export class AokSitemapTreeComponent {
  @Input() showReports = true;

  constructor(
    private contextState: ContextState,
    private kvnState: KvnState,
    private route: ActivatedRoute,
    @Inject(SITEMAP) readonly sitemap: /** @dynamic */ AokSitemap,
    @Optional() protected intl: AokSitemapIntlService
  ) {}

  resolveTitle(ref: SiteRef): string | null {
    return this.showReports || ref.title !== 'Berichte' || (ref.title === 'Berichte' && ref.children.length !== 1)
      ? ref.title || this.intl?.getSiteRefTitle(ref) || ref.key
      : undefined;
  }

  public filterChildren(children: SiteRef[]): SiteRef[] {
    return this.showReports ? children : children.filter((child) => child.title !== 'Berichte');
  }

  public interpolateContext(entry: SiteRef): string {
    const contextUrl = getContextSpecificUrl(entry.linkUrl, this.contextState);
    return getKvnStateUrl(contextUrl, this.route.snapshot, this.kvnState.isKvn());
  }
}
