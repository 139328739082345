import { isString } from './string.util';

const option: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'center' };

export function scrollToTop(parent = document): void {
  // check if we are in a dialog
  let elems = parent.getElementsByTagName('dialog-layout') as HTMLCollectionOf<HTMLDialogElement>;
  if (!elems) {
    elems = parent.getElementsByTagName('main') as HTMLCollectionOf<HTMLDialogElement>;
  }
  if (elems && elems.length > 0) {
    setTimeout(() => {
      elems.item(elems.length - 1)?.scroll({ top: 0, behavior: 'smooth' });
    }, 0);
  } else {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);
  }
}

export function scrollToElement(element?: string | HTMLElement): void {
  if (isString(element)) {
    const htmlElement: HTMLElement = document.getElementById(element);
    htmlElement?.scrollIntoView(option);
  } else element.scrollIntoView(option);
}

export function scrollToFragmentWithOffset(elementId: string, offset = 180): void {
  const element = document.getElementById(elementId);

  if (element) {
    const elementTop = element.offsetTop;

    const scrollToPosition = elementTop - offset;

    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
  }
}
