import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AokDmpCaseListOptions,
  AokPage,
  AokUser,
  DMPCase,
  DmpCaseFeedbackRequestSchema,
  DMPCaseManagementCounts,
} from '../schemas';
import { AokHttpParamEncoder } from '../utils';
import { AokApiBaseClient } from './api-base.client';

export const DMP_CASE_URL = new InjectionToken<string>('DMP_CASE_URL', {
  providedIn: 'root',
  factory: /** @dynamic */ () => '/api/insurance/dmpCases',
});

@Injectable({ providedIn: 'root' })
export class AokDmpCaseManagementClient extends AokApiBaseClient {
  constructor(@Inject(DMP_CASE_URL) readonly baseUrl: string, protected http: HttpClient) {
    super(baseUrl, http);
  }

  public list(options?: AokDmpCaseListOptions): Observable<AokPage<DMPCase>> {
    return this.http.get<AokPage<DMPCase>>(this.resolveUrl('/'), {
      params: new HttpParams({
        encoder: new AokHttpParamEncoder(),
        fromObject: { ...options },
      }),
    });
  }

  public markAsDone(id: number): Observable<void> {
    return this.http.patch<void>(this.resolveUrl(`done/${id}`), null);
  }

  public sendDmpCaseFeedback(feedbackRequest: DmpCaseFeedbackRequestSchema): Observable<void> {
    return this.http.post<void>(this.resolveUrl('feedback'), feedbackRequest);
  }

  public getCounts(lanr: string): Observable<DMPCaseManagementCounts> {
    return this.http.get<DMPCaseManagementCounts>(this.resolveUrl('counts'), {
      params: new HttpParams({ fromObject: { lanr } }),
    });
  }

  public getLastUpdatedDate(): Observable<number> {
    return this.http.get<number>(this.resolveUrl('lastUpdatedDate'));
  }

  public share(bsnr: string, userIdsWithAccess: number[]): Observable<void> {
    return this.http.post<void>(this.resolveUrl('share'), { bsnr, userIdsWithAccess });
  }

  public checkSharedState(bsnr: string): Observable<boolean> {
    return this.http.get<boolean>(this.resolveUrl('checkSharedState'), {
      params: new HttpParams({ fromObject: { bsnr } }),
    });
  }

  public getSharedWithDoctors(bsnr: string): Observable<number[]> {
    return this.http.get<number[]>(this.resolveUrl(`sharedWithDoctorIds`), {
      params: new HttpParams({ fromObject: { bsnr } }),
    });
  }

  public getLanrOptions(category: string): Observable<AokUser[]> {
    return this.http.get<AokUser[]>(this.resolveUrl('lanrFilterOptions'), {
      params: new HttpParams({ fromObject: { category } }),
    });
  }
}
