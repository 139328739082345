export enum AokAccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  // statuses below are not used on account status
  // TODO check if they are used somewhere; remove otherwise
  Pending = 'REQUEST_RECEIVED',
  Invited = 'INVITED',
  CardCreation = 'FOR_CARD_CREATION',
  ForReview = 'FOR_REVIEW',
}

export interface AokAccount {
  readonly id: number;
  accountStatus: AokAccountStatus;
}
