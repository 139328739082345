import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {
  A11yUtilsModule,
  AokFullNamePipeModule,
  AokPopoverComponent,
  AokPopoverContentTemplateComponent,
  AokRequiredLabelPipeModule,
  AokRouteDataPipeModule,
  AokServiceCategory,
  AokSvgIconComponent,
  DialogOverlayModule,
  DmpCaseManagementCategory,
  FeedbackCategory,
} from '@aok/common';
import {
  AokAlertModule,
  AokBreadcrumbsModule,
  AokCheckboxModule,
  AokDatepickerModule,
  AokDialogLayoutModule,
  AokDropdownModule,
  AokFooterModule,
  AokHintModule,
  AokInputFieldModule,
  AokNotificationBannerModule,
  AokRadioModule,
  AokTableModule,
  AokToTopScrollerModule,
  FeedbackButtonModule,
  FormControlErrorModule,
  HeaderModule,
  InfoBannerComponent,
  RequiredMarkModule,
} from '@aok/components';
import { environment } from '../../../environments/environment';
import { DMP_CM_CATEGORY_LABELS } from '../../config/dmp-case-management.config';
import { benefitsEnabledGuard } from '../../guards/benefits-enabled.guard';
import { dmpCaseEnabledGuard } from '../../guards/dmp-case-enabled.guard';
import { benefitResolver } from '../../resolvers/benefit.resolver';
import { benefitsResolver } from '../../resolvers/benefits.resolver';
import { dmpCasesResolver } from '../../resolvers/dmp-cases.resolver';
import { lanrFilterOptionsResolver } from '../../resolvers/lanr-filter-options.resolver';
import { dmpOverviewDataResolver } from '../../resolvers/dmp-overview-data.resolver';
import { dmpProgramsResolver } from '../../resolvers/dmp-programs.resolver';
import { favoriteBenefitsResolver } from '../../resolvers/favorite-benefits.resolver';
import { AokNotAccessiblePage } from '../not-accessible/not-accessible.component';
import { BenefitDetailViewComponent } from './benefits/benefit-detail-view/benefit-detail-view.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { BillingSheetResultComponent } from './billing-sheet/billing-sheet-result/billing-sheet-result.component';
import { BillingSheetComponent } from './billing-sheet/billing-sheet.component';
import { BillingSheetsPrintCollectionComponent } from './billing-sheets-print-collection/billing-sheets-print-collection.component';
import { BillingSheetsPrintCollectionModule } from './billing-sheets-print-collection/billing-sheets-print-collection.module';
import { DmpCaseManagementDetailViewComponent } from './dmp-case-management/dmp-case-management-detail-view/dmp-case-management-detail-view.component';
import { DmpCaseManagementOverviewComponent } from './dmp-case-management/dmp-case-management-overview/dmp-case-management-overview.component';
import { DmpCaseManagementComponent } from './dmp-case-management/dmp-case-management.component';
import { DmpCaseManagementModule } from './dmp-case-management/dmp-case-management.module';
import { HandoutsComponent } from './handouts/handouts.component';
import { PatientDetailSearchDialog } from './patient-detail-search-dialog/patient-detail-search-dialog.component';
import { ServiceOptionsInquiryComponent } from './service-options-inquiry/service-options-inquiry.component';
import { ServiceRequestFormComponent } from './service-request-form/service-request-form.component';
import { benefitCategoriesResolver } from '../../resolvers/benefit-categories.resolver';
import { benefitRegionsResolver } from '../../resolvers/benefit-regions.resolver';

export const APP_SERVICES_ROUTES: Routes = [
  {
    path: '',
    component: AokNotAccessiblePage,
    canActivate: [() => false],
  },
  {
    path: 'abrechnungsschein',
    component: BillingSheetComponent,
    data: {
      browserTitle: `Services - ${AokServiceCategory.BillingSheet}`,
      title: `${AokServiceCategory.BillingSheet}`,
      category: FeedbackCategory.ABRECHNUNGSSCHEIN,
    },
  },
  {
    path: 'sammeldruck',
    component: BillingSheetsPrintCollectionComponent,
    data: {
      browserTitle: `Services - ${AokServiceCategory.BatchPrinting}`,
      title: `${AokServiceCategory.BatchPrinting}`,
      category: FeedbackCategory.SAMMELDRUCK,
    },
  },
  {
    path: 'versichertenmerkmal',
    component: ServiceOptionsInquiryComponent,
    resolve: {
      programs: dmpProgramsResolver,
    },
    data: {
      browserTitle: 'Services - Versichertenmerkmal',
      title: 'Versichertenmerkmal',
      category: FeedbackCategory.VERSICHERTENMERKMAL,
    },
  },
  {
    path: 'dmp-falluebersicht',
    canActivate: [dmpCaseEnabledGuard],
    canActivateChild: [dmpCaseEnabledGuard],
    component: DmpCaseManagementComponent,
    data: {
      browserTitle: 'Services - DMP-Fallübersicht',
      title: 'DMP-Fallübersicht',
      category: FeedbackCategory.DMP_FALLUBERSICHT,
      sitemap: environment.enableDmpCaseManagement,
    },
    children: [
      {
        path: '',
        component: DmpCaseManagementOverviewComponent,
        runGuardsAndResolvers: 'always',
        resolve: { overviewData: dmpOverviewDataResolver },
        data: {
          browserTitle: 'Services - DMP-Fallübersicht',
          title: 'Services - DMP-Fallübersicht',
        },
      },
      {
        path: 'ausstehende-dokumentationen',
        component: DmpCaseManagementDetailViewComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          dmpCases: dmpCasesResolver,
          lanrFilterOptions: lanrFilterOptionsResolver,
        },
        data: {
          browserTitle: `Services - DMP-Fallübersicht - ${
            DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.REGULAR.toLowerCase()]
          }`,
          title: DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.REGULAR.toLowerCase()],
          dmpCategory: DmpCaseManagementCategory.REGULAR,
        },
      },
      {
        path: 'fehlende-erst-dokumentationen',
        component: DmpCaseManagementDetailViewComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          dmpCases: dmpCasesResolver,
          lanrFilterOptions: lanrFilterOptionsResolver,
        },
        data: {
          browserTitle: `Services - DMP-Fallübersicht - ${
            DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.INITIAL.toLowerCase()]
          }`,
          title: DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.INITIAL.toLowerCase()],
          dmpCategory: DmpCaseManagementCategory.INITIAL,
        },
      },
      {
        path: 'alle-einschreibungen',
        component: DmpCaseManagementDetailViewComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          dmpCases: dmpCasesResolver,
          lanrFilterOptions: lanrFilterOptionsResolver,
        },
        data: {
          browserTitle:
            `Services - DMP-Fallübersicht - ` +
            DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.ENROLLMENT.toLowerCase()],
          title: DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.ENROLLMENT.toLowerCase()],
          dmpCategory: DmpCaseManagementCategory.ENROLLMENT,
        },
      },
      {
        path: 'ausschreibungen',
        component: DmpCaseManagementDetailViewComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          dmpCases: dmpCasesResolver,
          lanrFilterOptions: lanrFilterOptionsResolver,
        },
        data: {
          browserTitle:
            `Services - DMP-Fallübersicht - ` +
            DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.SUSPENSION.toLowerCase()],
          title: DMP_CM_CATEGORY_LABELS[DmpCaseManagementCategory.SUSPENSION.toLowerCase()],
          dmpCategory: DmpCaseManagementCategory.SUSPENSION,
        },
      },
    ],
  },
  {
    path: 'handouts',
    component: HandoutsComponent,
    data: {
      browserTitle: 'Services - Handouts und Ausfüllhilfen',
      title: `Handouts und Ausfüllhilfen`,
      category: FeedbackCategory.FORM_COMPLETION_SUPPORT,
    },
  },
  {
    path: 'leistungssuche',
    canActivate: [benefitsEnabledGuard],
    canActivateChild: [benefitsEnabledGuard],
    data: {
      browserTitle: 'Services - Leistungssuche',
      title: `Leistungssuche`,
      category: FeedbackCategory.LEISTUNGSSUCHE,
      sitemap: environment.enableBenefits,
    },
    children: [
      {
        path: '',
        component: BenefitsComponent,
        runGuardsAndResolvers: 'always',
        resolve: {
          benefits: benefitsResolver,
          favoriteBenefits: favoriteBenefitsResolver,
          categories: benefitCategoriesResolver,
          regions: benefitRegionsResolver,
        },
      },
      {
        path: ':benefitId',
        component: BenefitDetailViewComponent,
        resolve: { benefit: benefitResolver },
        data: {
          browserTitle: (d) => `Services - Leistungssuche - ${d.benefit?.name}`,
          title: (d) => d.benefit?.name,
          sitemap: false,
        },
      },
    ],
  },
  // old routes before epic (R097) Combine services that need to be kept to redirect users that have bookmarks
  {
    path: 'zuzahlungsbefreiung',
    redirectTo: 'versichertenmerkmal',
    data: {
      sitemap: false,
    },
  },
  {
    path: 'dmp-status',
    redirectTo: 'versichertenmerkmal',
    data: {
      sitemap: false,
    },
  },
  {
    path: 'pflegegradabfrage',
    redirectTo: 'versichertenmerkmal',
    data: {
      sitemap: false,
    },
  },
  // old route before AOKNAPE-7437 that needs to be kept to redirect users that have bookmarks
  {
    path: 'dmp-fallfuehrung',
    redirectTo: 'dmp-falluebersicht',
    data: {
      sitemap: false,
    },
  },
];

@NgModule({
  declarations: [
    ServiceRequestFormComponent,
    ServiceOptionsInquiryComponent,
    HandoutsComponent,
    BillingSheetComponent,
    BillingSheetResultComponent,
    PatientDetailSearchDialog,
  ],
  imports: [
    DialogOverlayModule,
    DmpCaseManagementModule,
    BillingSheetsPrintCollectionModule,
    AokBreadcrumbsModule,
    RouterModule.forChild(APP_SERVICES_ROUTES),
    CommonModule,
    FormControlErrorModule,
    AokAlertModule,
    AokRouteDataPipeModule,
    FormsModule,
    AokNotificationBannerModule,
    AokSvgIconComponent,
    HeaderModule,
    FeedbackButtonModule,
    AokFooterModule,
    RequiredMarkModule,
    AokDatepickerModule,
    AokInputFieldModule,
    AokToTopScrollerModule,
    AokCheckboxModule,
    ReactiveFormsModule,
    AokHintModule,
    A11yUtilsModule,
    AokRequiredLabelPipeModule,
    AokTableModule,
    AokPopoverComponent,
    AokDropdownModule,
    AokFullNamePipeModule,
    AokRadioModule,
    AokDialogLayoutModule,
    A11yModule,
    InfoBannerComponent,
    AokPopoverContentTemplateComponent,
  ],
})
export class ServicesModule {}
