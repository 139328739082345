import { getCurrentQuarterStartDate, getPreviousQuarterStartDate } from '../date.util';

export function getMinTreatmentDate(currentDate: Date): Date {
  const currDateUtc = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
  const currentQuarter = getCurrentQuarterStartDate(currDateUtc);
  const deadline = new Date(currentQuarter.getUTCFullYear(), currentQuarter.getUTCMonth() + 1, 10);
  if (currDateUtc <= deadline) {
    return getPreviousQuarterStartDate(currDateUtc);
  }
  return currentQuarter;
}
