import { AokAccount, AokAccountStatus } from '../account.schema';
import { AokOrg } from '../org.schema';
import { AokPageQueryParams } from '../page.schema';
import { AuthOption } from './user-registration.schema';

export interface AokBaseUser extends AokAccount {
  authOption: AuthOption | string;
  countryCode: string;
  secondFactorPhoneNumber: string;
  createdDate: string;
  email: string;
  firstName: string;
  keycloakId: string;
  lastName: string;
  userType: KnownUserType;
}

export interface AokUser extends AokBaseUser {
  gender: KnownUserGender | string;
  mobilePhone?: string;
  practitionerResource?: AokPractitionerResource;
  salutation: KnownUserSalutation | string;

  /** Sets the requested practitioner resource. Only used for user requests! */
  practitionerRequest?: AokPractitionerResource;
}

export interface AokCmsUser extends AokBaseUser {
  bsnrsData?: AokUserBsnrsData;
  hasOtpConfigured?: boolean;
  lanr?: number;
  title?: string;
  specialization?: AokPractitionerSpecializationEntry;
}

export interface AokDoctorRelationship {
  links: Array<any>;
  // only partial data sent; might need to ask BE to send full data
  organizationData: Partial<AokOrg>;
  userData?: Partial<AokUser>;
}

export type AokPractitionerResource = AokAssistantResource | AokDoctorResource | any;

export interface AokAssistantResource {
  readonly id?: string; // always the same as the parent {@link AokUser} resource
  readonly organizationsIds: string[];
  readonly title: string;
}

export interface AokDoctorResource extends AokAssistantResource {
  specialization: AokPractitionerSpecializationEntry;
  // array of specialization description; needed by BE
  specializations: string[];
  lanr: number; // == 7-digits
  doctorType: DoctorType;
  kvnPracticeAccountControl: boolean;
  pendingSamlBsnrs?: string[];
}

export enum KnownUserType {
  Doctor = 'DOCTOR',
  Kvn_Doctor = 'KVN_DOCTOR',
  Full_Kvn_Doctor = 'FULL_KVN_DOCTOR',
  Assistant = 'ASSISTANT',
  Kvn_Practice_Team = 'KVN_PRACTICE_TEAM',
  Employee = 'EMPLOYEE',
  Admin = 'aok_admin',
}

export enum KnownUserSalutation {
  Mrs = 'MRS',
  Mr = 'MR',
  None = 'NONE',
}

export enum KnownUserGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Diverse = 'DIVERSE',
}

export enum AokUserRealm {
  DOCTOR_PORTAL = 'doctor-portal',
  CMS = 'cms',
}

/** Describes a flat object map of specialization ids and their respective german descriptor label */
export interface AokPractitionerSpecializations {
  /** Gets the german descriptor label for the given specialization id */
  [id: string]: string;
}

export interface Seed {
  seed: string;
}

export interface AokPractitionerSpecializationEntry {
  id: string;
  description: string;
}

export enum AokContractType {
  Hzv = 'HZV',
}

export interface AokUserListOptions extends AokPageQueryParams {
  status?: AokAccountStatus[] | AokAccountStatus;
  userType?: KnownUserType | KnownUserType[];
  id?: Array<string | number> | string | number;
  email?: string[] | string;
  lanr?: string[] | string;
  bsnr?: string[] | string;
  keycloakId?: string;
}

export enum DoctorType {
  OWNER = 'OWNER',
  EMPLOYED = 'EMPLOYED',
}

export interface AokUserBsnrsData {
  bsnr: string;
  organizationName: string;
  location: string;
  organizationType: string;
  searchMatch: boolean;
}
