import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { A11yUtilsModule, AokFullNamePipeModule, AokRemoveDateModule, AokSvgIconComponent } from '@aok/common';
import {
  AokDropdownModule,
  AokFooterModule,
  AokInputFieldModule,
  AokMenuButtonModule,
  AokPaginationModule,
  AokTabsModule,
  FeedbackButtonModule,
  FeedbackDmpCaseDialogModule,
  FilterAreaComponent,
  FilterButtonComponent,
  HeaderModule,
  InfoBannerComponent,
  SearchInputModule,
} from '@aok/components';
import { DmpCaseManagementDetailViewComponent } from './dmp-case-management-detail-view/dmp-case-management-detail-view.component';
import { DmpCaseManagementOverviewComponent } from './dmp-case-management-overview/dmp-case-management-overview.component';
import { DmpCaseManagementComponent } from './dmp-case-management.component';

@NgModule({
  declarations: [DmpCaseManagementOverviewComponent, DmpCaseManagementComponent, DmpCaseManagementDetailViewComponent],
  imports: [
    CommonModule,
    HeaderModule,
    ReactiveFormsModule,
    FeedbackButtonModule,
    AokFooterModule,
    AokSvgIconComponent,
    A11yUtilsModule,
    RouterModule,
    AokTabsModule,
    CdkTableModule,
    AokRemoveDateModule,
    AokMenuButtonModule,
    AokDropdownModule,
    AokInputFieldModule,
    AokPaginationModule,
    FeedbackDmpCaseDialogModule,
    SearchInputModule,
    InfoBannerComponent,
    FilterAreaComponent,
    FilterButtonComponent,
    AokFullNamePipeModule,
  ],
})
export class DmpCaseManagementModule {}
