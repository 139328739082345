<dialog-layout [headerText]="msgLabel" [main]="false" [fullscreen]="true" id="dialog" cdkTrapFocus aokFocusFirstChild>
  <div *ngIf="isInviteMode()" class="aok-container">
    <p class="aok-invite-hint">
      Senden Sie einem Praxismitarbeiter eine Einladung zur Registrierung ins AOK-Arztportal. Mit der Registrierung wird
      ein eigenständiger Nutzer angelegt, den Sie im Profibereich verwalten können. Bereits eingeladene
      Praxismitarbeiter können selbstständig Anfragen auf Zugehörigkeit an alle Praxen stellen, in denen sie arbeiten.
    </p>
  </div>

  <ng-container *ngIf="errorIds.length">
    <ng-container *ngTemplateOutlet="userMessages"></ng-container>
  </ng-container>

  <div>
    <div class="aok-container">
      <div class="required required-label">
        <aok-required-mark></aok-required-mark>
        Pflichtfeld
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="aok-container">
      <h5>Persönliche Daten</h5>
      <aok-input-field [label]="formLabels['salutation']" [required]="true" id="salutation">
        <aok-dropdown [control]="form.get('salutation')" [options]="salutationOptions"></aok-dropdown>
      </aok-input-field>

      <aok-input-field [label]="formLabels['title']" id="title">
        <input formControlName="title" />
      </aok-input-field>

      <aok-input-field [label]="formLabels['firstname']" [required]="true" id="firstname">
        <input formControlName="firstname" />
      </aok-input-field>

      <aok-input-field [label]="formLabels['lastname']" [required]="true" id="lastname">
        <input formControlName="lastname" />
      </aok-input-field>

      <h5 id="headlineContactData">Kontaktdaten</h5>

      <div class="mailAddress">
        <aok-input-field [label]="formLabels['mailAddress']" [required]="true" id="mailAddress">
          <input formControlName="mailAddress" />
        </aok-input-field>
        <aok-popover [direction]="popoverDirection" [radius]="20" [width]="350" [yaxis]="50">
          <p>
            {{ hintPopoverMail }}
          </p>
        </aok-popover>
      </div>

      <aok-input-field [label]="formLabels['phoneNumber']" id="phoneNumber">
        <input formControlName="phoneNumber" />
      </aok-input-field>

      <h5 *ngIf="isInviteMode()" id="headlineConsentDeclaration">Einverständniserklärung</h5>

      <aok-input-field *ngIf="isInviteMode()" id="consentDeclaration">
        <aok-checkbox
          [label]="formLabels['consentDeclaration'].label"
          formControlName="consentDeclaration"
        ></aok-checkbox>
      </aok-input-field>

      <div id="actionButtons">
        <div id="submitButton">
          <button (click)="submit()">{{ labelSubmitButton }}</button>
        </div>

        <button (click)="deleteInvitation()" *ngIf="isInvited()" class="secondary">Einladung löschen</button>

        <button (click)="removePracticeEmployee()" *ngIf="displayRemovePracticeStaffButton" class="secondary">
          Praxismitarbeiter entfernen
        </button>
      </div>
    </div>
  </form>
</dialog-layout>

<ng-template #userMessages>
  <div class="aok-container messages">
    <aok-notification-banner
      [bannerHeadline]="bannerHeadline"
      [bannerLabel]="bannerLabel"
      [bannerLinks]="errorIds"
      accent="bad"
    >
    </aok-notification-banner>
  </div>
</ng-template>
