import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AokBsnrRegistration,
  AokBsnrRegistrationStatus,
  AokHttpHeaders,
  AokPage,
  AokRegistrationListOptions,
  AokRegistrationOrgAsMfaDataRequest,
  AokRegistrationOrgData,
} from '../schemas';
import { AokApiBaseClient } from './api-base.client';

@Injectable({ providedIn: 'root' })
export class AokBsnrRegistrationClient extends AokApiBaseClient {
  list(options?: AokRegistrationListOptions, headers?: AokHttpHeaders): Observable<AokPage<AokBsnrRegistration>> {
    return this.http.get<AokPage<AokBsnrRegistration>>(this.resolveUrl(), {
      headers,
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  find(id: NonNullable<number>): Observable<AokBsnrRegistration> {
    return this.http.get<AokBsnrRegistration>(this.resolveUrl(id.toString()));
  }

  create(org: AokRegistrationOrgData | AokRegistrationOrgAsMfaDataRequest): Observable<AokRegistrationOrgData> {
    return this.http.post<AokRegistrationOrgData>(this.resolveUrl(), org);
  }

  update(id: number, org: AokRegistrationOrgData): Observable<unknown> {
    return this.http.put(this.resolveUrl(id.toString()), org);
  }

  registrationStatusChange(id: NonNullable<string | number>, status: AokBsnrRegistrationStatus): Observable<unknown> {
    return this.http.patch(this.resolveUrl(id.toString()), { status });
  }

  reject(id: NonNullable<number>, reason: string): Observable<unknown> {
    return this.http.patch(this.resolveUrl(id.toString()), { status: AokBsnrRegistrationStatus.REJECTED, reason });
  }

  delete(id: NonNullable<number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }

  startInternalCheck(id: number, payload: { reason: string; status: string }): Observable<unknown> {
    return this.http.patch(this.resolveUrl(id.toString()), payload);
  }

  endInternalCheck(id: number, status: string): Observable<unknown> {
    return this.http.patch(this.resolveUrl(id.toString()), { status });
  }

  /**
   * Resolves a fully quantified service url using the base ":apiUrl/bsnrs/registrations"
   *
   * @param segments Additional segments that should be append to the url
   */
  resolveUrl(...segments: string[]): string {
    return super.resolveUrl('users', 'bsnr-registrations', ...segments);
  }
}
