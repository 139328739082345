import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AokCardReaderError,
  AokPatientSearchTypeEnum,
  AokServiceRequest,
  explicitlyRequiredTrue,
  houseNumber,
  insuranceFormat,
  name,
  removeUnusedAttributes,
  zipCode,
} from '@aok/common';
import { RadioOptions } from '@aok/components';
import { distinctUntilChanged, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatomoCustomVariable } from '../../../config/matomo.config';
import { MatomoService } from '../../../services/matomo.service';
import { ErrorBannerService } from './error-banner.service';
import { CardReaderService } from '@aok/components';

@Component({
  selector: 'aok-cockpit-service-request-form',
  styleUrls: ['service-request-form.component.scss'],
  templateUrl: 'service-request-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ServiceRequestFormComponent implements OnInit, OnDestroy {
  @Input() searchData: AokServiceRequest = null;
  @Input() submitButtonText = 'Status abfragen';
  @Input() identityCheck = true;
  @Input() focusFirstChild = true;
  @Input() showDetailedAddressForm = false;
  @Input() showCardReaderOption = false;
  @Input() patientSearchType: AokPatientSearchTypeEnum;

  @Output() patientData = new EventEmitter<AokServiceRequest>();
  @Output() patientFormErrors = new EventEmitter<{ id: string; label: string }[]>();
  @Output() radioOptionChanged = new EventEmitter<AokPatientSearchTypeEnum>();
  @Output() cardReaderError = new EventEmitter<AokCardReaderError>();

  public fieldData = {
    firstname: {
      id: 'firstname',
      label: 'Vorname',
    },
    birthday: {
      id: 'birthday',
      label: 'Geburtsdatum',
    },
    lastname: {
      id: 'lastname',
      label: 'Nachname',
    },
    postCode: {
      id: 'postCode',
      label: 'PLZ',
    },
    street: {
      id: 'street',
      label: 'Straße',
    },
    streetNumber: {
      id: 'streetNumber',
      label: 'Hausnummer',
    },
    insuranceNumber: {
      id: 'insuranceNumber',
      label: 'Versichertennummer',
    },
    identityCheck: {
      id: 'identityCheck',
      label: 'Identitätsprüfung',
    },
  };

  public switchRadioOptions: RadioOptions[] = [
    {
      label: 'Name und Geburtsdatum',
      value: AokPatientSearchTypeEnum.Address,
      id: 'ServiceSelectionNameBirthday',
    },
    {
      label: 'Versichertennummer und Geburtsdatum',
      value: AokPatientSearchTypeEnum.Insurance,
      id: 'ServiceSelectionInsuranceNumber',
    },
  ];
  public radioSwitchControl = new UntypedFormControl();

  public showInsuranceForm = false;

  public insuranceForm = new UntypedFormGroup({
    [this.fieldData.birthday.id]: new UntypedFormControl(null, [Validators.required]),
    [this.fieldData.insuranceNumber.id]: new UntypedFormControl(null, [Validators.required, insuranceFormat]),
  });

  public addressForm = new UntypedFormGroup({
    [this.fieldData.firstname.id]: new UntypedFormControl(null, [name('firstname')]),
    [this.fieldData.birthday.id]: new UntypedFormControl(null, [Validators.required]),
    [this.fieldData.lastname.id]: new UntypedFormControl(null, [Validators.required, name('lastname')]),
    [this.fieldData.postCode.id]: new UntypedFormControl(null, [zipCode]),
    [this.fieldData.street.id]: new UntypedFormControl(null, []),
    [this.fieldData.streetNumber.id]: new UntypedFormControl(null, [houseNumber]),
  });
  public requiredValidator = Validators.required;

  public identityCheckLabel = 'Hiermit bestätige ich die Überprüfung der Identität des Patienten.*';
  public identityCheckControl: UntypedFormControl;
  public AokPatientSearchTypeEnum = AokPatientSearchTypeEnum;

  private onDestroy = new Subject<void>();
  private showsError = false;

  constructor(
    private matomoService: MatomoService,
    private errorBannerService: ErrorBannerService,
    private cardReaderService: CardReaderService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.updateRadioSwitchOptions();
    this.radioSwitchHandler();

    if (this.identityCheck) {
      this.identityCheckControl = new UntypedFormControl(false, explicitlyRequiredTrue);
    }

    if (this.searchData) {
      this.preparePatientSearch();
      this.cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.matomoService.deleteMatomoCustomVariable(MatomoCustomVariable.PATIENT_SEARCH_TYPE);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public getController(formControlName: string): UntypedFormControl {
    if (this.radioSwitchControl.value === AokPatientSearchTypeEnum.Address) {
      return this.addressForm.get(formControlName) as UntypedFormControl;
    } else {
      return this.insuranceForm.get(formControlName) as UntypedFormControl;
    }
  }

  public submit(): void {
    const addressFormInvalid = !this.showInsuranceForm && this.addressForm.invalid;
    const insuranceNumberFormInvalid = this.showInsuranceForm && this.insuranceForm.invalid;
    const identityVerificationValid = this.identityCheck && this.identityCheckControl.invalid;
    if (addressFormInvalid || insuranceNumberFormInvalid || identityVerificationValid) {
      this.invalidCheck();
    } else {
      const patientData = this.buildPatientRequest();
      this.patientData.emit(patientData);
    }
  }

  public invalidCheck(): void {
    this.showsError = true;

    const errorIds: string[] = [];
    if (this.showInsuranceForm) {
      errorIds.push(...this.errorBannerService.getInputFieldsErrorIds(this.insuranceForm));
    } else {
      errorIds.push(...this.errorBannerService.getInputFieldsErrorIds(this.addressForm));
    }
    if (this.identityCheck && this.identityCheckControl?.invalid) {
      this.identityCheckControl.updateValueAndValidity();
      errorIds.push(this.fieldData.identityCheck.id);
    }

    this.patientFormErrors.emit(
      errorIds.map((errorId) => {
        return {
          id: errorId,
          label: this.fieldData[errorId].label,
        };
      })
    );
  }

  public readInsuranceCardData(): void {
    this.cardReaderService.tryStartWebBridgeAndReadEgkData(this.patientData, this.cardReaderError);
  }

  private preparePatientSearch(): void {
    if (this.searchData.insuranceNumber) {
      this.radioSwitchControl.setValue(AokPatientSearchTypeEnum.Insurance);
      this.insuranceForm.get('insuranceNumber').setValue(this.searchData.insuranceNumber);
      this.insuranceForm.get('birthday').setValue(this.searchData.dateOfBirth);
    } else {
      if (this.searchData.street || this.searchData.streetNumber) {
        this.showDetailedAddressForm = true;
      }
      this.addressForm.get('birthday').setValue(this.searchData.dateOfBirth);
      this.addressForm.get('street').setValue(this.searchData.street);
      this.addressForm.get('streetNumber').setValue(this.searchData.streetNumber);
      this.addressForm.get('postCode').setValue(this.searchData.zip);
    }

    this.addressForm.get('firstname').setValue(this.searchData.firstName);
    this.addressForm.get('lastname').setValue(this.searchData.lastName);
  }

  private updateRadioSwitchOptions(): void {
    if (this.showCardReaderOption) {
      this.switchRadioOptions.push({
        label: 'Versichertenkarte einlesen',
        value: AokPatientSearchTypeEnum.InsuranceCard,
        id: 'ServiceSelectionInsuranceCard',
      });
    }
  }

  private radioSwitchHandler(): void {
    if (!this.patientSearchType) {
      this.radioSwitchControl.setValue(AokPatientSearchTypeEnum.Address, { emitEvent: false });
    } else {
      this.radioSwitchControl.setValue(this.patientSearchType, { emitEvent: false });
    }

    this.radioSwitchControl.valueChanges
      .pipe(takeUntil(this.onDestroy), distinctUntilChanged())
      .subscribe((value: AokPatientSearchTypeEnum) => {
        this.radioOptionChanged.emit(value);
        this.displayFormBasedOnRadio(value);

        if (value !== AokPatientSearchTypeEnum.InsuranceCard && this.showsError) {
          setTimeout(() => this.invalidCheck());
        } else {
          this.patientFormErrors.emit([]);
        }
      });
  }

  private displayFormBasedOnRadio(value: AokPatientSearchTypeEnum): void {
    if (value === AokPatientSearchTypeEnum.Address) {
      this.showInsuranceForm = false;
      const birthday: Date = this.insuranceForm?.value?.birthday as Date;
      if (birthday) {
        this.addressForm.get('birthday').setValue(birthday);
      }
    } else if (value === AokPatientSearchTypeEnum.Insurance) {
      this.showInsuranceForm = true;
      const birthday = this.addressForm?.value?.birthday;
      if (birthday) {
        this.insuranceForm.get('birthday').setValue(birthday);
      }
    } else {
      this.showInsuranceForm = false;
    }

    this.matomoService.setMatomoPatientSearchVariable(value);
  }

  private buildPatientRequest(): AokServiceRequest {
    if (this.showInsuranceForm) {
      return removeUnusedAttributes({
        checkboxIdentityCheck: !this.identityCheck ? true : this.identityCheckControl.value,
        insuranceNumber: this.insuranceForm.get(this.fieldData.insuranceNumber.id).value,
        dateOfBirth: this.insuranceForm.get(this.fieldData.birthday.id).value,
      }) as AokServiceRequest;
    } else {
      const { postCode, street, streetNumber, birthday, firstname, lastname } = this.fieldData;
      return removeUnusedAttributes({
        zip: this.addressForm.get(postCode.id).value,
        checkboxIdentityCheck: !this.identityCheck ? true : this.identityCheckControl.value,
        street: this.addressForm.get(street.id).value,
        dateOfBirth: this.addressForm.get(birthday.id).value,
        streetNumber: this.addressForm.get(streetNumber.id).value,
        firstName: this.addressForm.get(firstname.id).value,
        lastName: this.addressForm.get(lastname.id).value,
      }) as AokServiceRequest;
    }
  }
}
