import { AokAccountStatus } from '../../schemas';

export const getAccountStatusText = (status: AokAccountStatus): string => {
  switch (status) {
    case AokAccountStatus.Active:
      return 'aktiv';
    case AokAccountStatus.Inactive:
      return 'inaktiv';
    case AokAccountStatus.ForReview:
    case AokAccountStatus.Pending:
      return 'Anfrage eingegangen';
    case AokAccountStatus.Invited:
      return 'eingeladen';
    case AokAccountStatus.CardCreation:
      return 'Displaykarte wird erstellt';
    default:
      return status || '';
  }
};
