import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AokFullNamePipe,
  AokReminder,
  AokReminderService,
  ContextState,
  CurrentUserState,
  getContextSpecificUrl,
  getKvnStateUrl,
  getSalutationText,
  KnownUserSalutation,
} from '@aok/common';
import {
  AokCard,
  AokHeaderService,
  KvnState,
  NotificationSidebarService,
  ReminderActionService,
  TileSchema,
} from '@aok/components';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const INSURANCE_STATUS_SHORTCUT: AokCard = {
  iconName: '0214-news',
  label: 'Abrechnungs&shy;schein',
  link: '/services/abrechnungsschein',
};
const SERVICE_INQUIRY_SHORTCUT: AokCard = {
  iconName: '0000-checklist',
  label: 'Versichertenmerkmal',
  link: '/services/versichertenmerkmal',
};
const DMP_CASE_MANAGEMENT_SHORTCUT: AokCard = {
  iconName: '0000-checklist',
  label: 'DMP-Fallübersicht',
  link: '/services/dmp-falluebersicht',
};

@Component({
  selector: 'aok-cockpit-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnDestroy, OnInit {
  public reminders: AokReminder[];
  public podcastTile: TileSchema = {
    title: 'AOK Praxis-Talk',
    link: '/aktuelles/podcasts',
    image: 'assets/img/podcasts.png',
    alt: 'Podcast AOK Praxis-Talk',
  };

  private _fullNamePipe = new AokFullNamePipe();
  private ngDestroyed: Subject<void>;
  private SVS_SHORTCUT: AokCard = {
    iconName: '0000-checklist',
    label: 'HzV-Übersicht',
    fn: () => {
      const url = getContextSpecificUrl(`${environment.svsAppEntrypointUrl}/hzv`, this.contextState);
      window.location.href = getKvnStateUrl(url, this.route.snapshot, this.kvnState.isKvn());
    },
  };

  private _shortcuts: AokCard[] = [INSURANCE_STATUS_SHORTCUT, this.SVS_SHORTCUT, SERVICE_INQUIRY_SHORTCUT];

  get shortcuts(): AokCard[] {
    while (this._shortcuts.length % 4 !== 0) {
      this._shortcuts.push(null);
    }

    return this._shortcuts;
  }

  get pageTitle(): string {
    const activeUser = this.activeUser.snapshot;

    let text = 'Herzlich Willkommen';
    if (!activeUser || this.kvnState.isKvnPracticeTeam()) return text;

    const hasSalutation = activeUser.salutation !== KnownUserSalutation.None;
    if (hasSalutation) text += ` ${getSalutationText(activeUser.salutation)}`;

    const fullNameFormat = hasSalutation ? 'TT LL' : 'TT FF LL';
    text += ` ${this._fullNamePipe.transform(activeUser, fullNameFormat, activeUser.practitionerResource)}`;
    return text;
  }

  constructor(
    protected activeUser: CurrentUserState,
    protected router: Router,
    private route: ActivatedRoute,
    private reminderService: AokReminderService,
    private headerService: AokHeaderService,
    private notificationSidebarService: NotificationSidebarService,
    private reminderActionService: ReminderActionService,
    private contextState: ContextState,
    private kvnState: KvnState
  ) {
    if (environment.enableDmpCaseManagement) {
      this._shortcuts.splice(2, 0, DMP_CASE_MANAGEMENT_SHORTCUT);
    }

    this.ngDestroyed = new Subject<void>();
    this.reminders = this.route.snapshot.data['reminders'];
    this.updateReminders();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        takeUntil(this.ngDestroyed),
        map((data) => data['reminders'])
      )
      .subscribe((reminders) => (this.reminders = reminders));
  }

  ngOnDestroy(): void {
    this.ngDestroyed.next();
    this.ngDestroyed.complete();
  }

  /**
   * At the moment we do not need this function. But it should probably be installed again in the future.
   */
  public openNotificationSidebar(): void {
    this.notificationSidebarService.openNotificationSidebar().subscribe(() => {
      this.headerService.updateNotificationCount().subscribe();
    });
  }

  /**
   * Handle action when user clicks on reminder action link
   * @param reminder
   */
  public handleActionLinkClick(reminder: AokReminder): void {
    this.reminderActionService.handleReminderLinkClick(reminder);
  }

  /**
   * update the reminders list when the context changes
   */
  private updateReminders(): void {
    const enableNotifications = environment.enableNotifications;
    if (!enableNotifications) {
      return;
    }

    this.reminderService
      .retrieveDashboardRemindersOnContextChange()
      .pipe(takeUntil(this.ngDestroyed))
      .subscribe((reminders) => {
        this.reminders = reminders;
      });
  }
}
