import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AokPopoverComponent, AokSvgIconComponent, PopoverDirection } from '@aok/common';
import { AokInputFieldModule } from '../cdk';
import { AokCheckboxModule } from '../checkbox/checkbox.module';

@Component({
  selector: 'aok-completion-support',
  templateUrl: './completion-support.component.html',
  styleUrls: ['./completion-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AokPopoverComponent,
    AokSvgIconComponent,
    AokCheckboxModule,
    ReactiveFormsModule,
    AokInputFieldModule,
  ],
})
export class CompletionSupportComponent {
  public highlightRequiredFields = true;
  public showSampleData = false;

  public popoverContainerWidth = 460;
  public popoverRadius = 20;

  public popoverRightCenter: PopoverDirection = PopoverDirection.RightCenter;
  public popoverRightTop: PopoverDirection = PopoverDirection.RightTop;
  public popoverBottomRight: PopoverDirection = PopoverDirection.BottomRight;
  public popoverTopLeft: PopoverDirection = PopoverDirection.TopLeft;
  public popoverLeftCenter: PopoverDirection = PopoverDirection.LeftCenter;

  public get hkpPopoverIconName(): string {
    return this.highlightRequiredFields ? 'question-circle_filled' : 'question-circle';
  }

  public get hkpPopoverIconSize(): number {
    return this.highlightRequiredFields ? 23 : 22;
  }
}
