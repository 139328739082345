import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AokCmsOrg, AokOrg, AokOrgListOptions, AokPage, AokPageQueryParams, HistoryEntry } from '../schemas';
import { AokApiBaseClient } from './api-base.client';

@Injectable({ providedIn: 'root' })
export class AokOrgClient extends AokApiBaseClient {
  /**
   * Returns an array of organisations at a page object.
   * @param options
   */
  list(options?: AokOrgListOptions): Observable<AokPage<AokOrg>> {
    return this.http.get<AokPage<AokOrg>>(this.resolveUrl(), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  public getOrgById(id: number): Observable<AokOrg> {
    return this.http.get<AokOrg>(this.resolveUrl(id.toString()));
  }

  cmsList(options?: AokOrgListOptions): Observable<AokPage<AokCmsOrg>> {
    return this.http.get<AokPage<AokCmsOrg>>(this.resolveUrl(), {
      headers: { Accept: 'application/vnd.arztportal.cms.practices.v1+json' },
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  /**
   * returns a Organisation
   * @param id
   */
  find(id: NonNullable<string | number>): Observable<AokOrg> {
    return this.http.get<AokOrg>(this.resolveUrl(id.toString()));
  }

  /**
   * returns all doctors practices in a Page object.
   * @param options
   */
  listMine(options?: AokPageQueryParams): Observable<AokPage<AokOrg>> {
    return this.http.get<AokPage<AokOrg>>(this.resolveUrl('my'), {
      params: new HttpParams({ fromObject: { ...options } }),
    });
  }

  create(org: AokOrg): Observable<AokOrg> {
    return this.http.post<AokOrg>(this.resolveUrl(), org);
  }

  update(id: NonNullable<string | number>, org: Partial<AokOrg>): Observable<AokOrg> {
    return this.http.put<AokOrg>(this.resolveUrl(id.toString()), org);
  }

  delete(id: NonNullable<string | number>): Observable<void> {
    return this.http.delete<void>(this.resolveUrl(id.toString()));
  }

  listTypes(): Observable<string[]> {
    return this.http.get<string[]>(this.resolveUrl('types'));
  }

  downloadOrganizations(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.resolveUrl('export'), {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  my(): Observable<AokPage<AokOrg>> {
    return this.http.get<AokPage<AokOrg>>(this.resolveUrl('my'));
  }

  getHistoryEntries(orgId: string): Observable<HistoryEntry[]> {
    return this.http.get<HistoryEntry[]>(this.resolveUrl(orgId, 'history'));
  }

  updatePracticeData(): Observable<AokOrg[]> {
    return this.http.post<AokOrg[]>(this.resolveUrl('updatePracticeData'), {});
  }

  /**
   * Resolves a fully quantified service url using the base ":apiUrl/organizations"
   *
   * @param segments Additional segments that should be append to the url
   */
  resolveUrl(...segments: string[]): string {
    return super.resolveUrl('organizations', ...segments);
  }
}
