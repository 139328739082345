import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  AokInsuranceStatusClient,
  AokInsuree,
  AokServiceRequest,
  billingQuarter,
  getMinTreatmentDate,
} from '@aok/common';
import { takeUntil } from 'rxjs/operators';
import { ErrorBannerService } from '../service-request-form/error-banner.service';
import { ServicePageComponent } from '../service-page.component';

@Component({
  selector: 'aok-cockpit-billing-sheet',
  styleUrls: ['billing-sheet.component.scss'],
  templateUrl: 'billing-sheet.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BillingSheetComponent extends ServicePageComponent<AokInsuree> implements OnInit {
  public treatmentDateFieldData = { id: 'treatmentDate', label: 'Behandlungsdatum' };
  public minTreatmentDate: Date = getMinTreatmentDate(new Date());
  public maxTreatmentDate: Date = new Date();
  public treatmentDateForm: UntypedFormControl = new UntypedFormControl(new Date(), {
    validators: [Validators.required, billingQuarter],
    updateOn: 'blur',
  });

  constructor(protected client: AokInsuranceStatusClient, protected errorBannerService: ErrorBannerService) {
    super(client, errorBannerService);
  }

  ngOnInit(): void {
    this.handleTreatmentControlChanges();
  }

  public handlePatientFormError(errors: { id: string; label: string }[]): void {
    if (this.treatmentDateForm.invalid) {
      errors.push(this.treatmentDateFieldData);
    }
    this.handleErrorBanner(errors);
  }

  public handlePatientFormData(request: AokServiceRequest): void {
    if (this.treatmentDateForm.invalid) {
      this.handleErrorBanner([this.treatmentDateFieldData]);
    } else {
      this.previousPatientSearchData = request;
      this.handleRequest(request);
    }
  }

  private handleRequest(request: AokServiceRequest): void {
    const invoiceDate = this.treatmentDateForm.value;
    this.client.check({ ...request, invoiceDate }).subscribe({
      next: this.handleResponse(),
      error: this.handleError(),
    });
  }

  private handleTreatmentControlChanges(): void {
    this.treatmentDateForm?.valueChanges.pipe(takeUntil(this.ngDestroys)).subscribe((date: Date) => {
      const treatmentDate = new Date(date).getTime();
      const maxTreatmentDate = new Date(this.maxTreatmentDate).getTime();
      const minTreatmentDate = new Date(this.minTreatmentDate).getTime();

      if (treatmentDate > maxTreatmentDate || treatmentDate < minTreatmentDate) {
        this.treatmentDateForm.setValue(new Date(), { emitEvent: false });
      }
    });
  }
}
