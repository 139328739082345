import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yUtilsModule, AokFullAddressPipe, AokFullNamePipeModule, TrimControlDirective } from '@aok/common';
import { AokHintModule, AokInputFieldModule, RequiredMarkModule } from '../../cdk';
import { AokCheckboxModule } from '../../checkbox/checkbox.module';
import { AokDropdownModule } from '../../dropdown/dropdown.module';
import { FormControlErrorModule } from '../../form-control-error/module';
import { AokEmailErrorMessageModule } from '../../form-error-message/email-error-message/email-error-message.module';
import { AokRequiredErrorMessageModule } from '../../form-error-message/required-error-message/required-error-message.module';
import { AokNotificationBannerModule } from '../../notification-banner/notification-banner.module';
import { AokDialogLayoutModule } from '../dialog-layout/dialog-layout.module';
import { FeedbackDmpCaseDialog } from './feedback-dmp-case-dialog.component';

@NgModule({
  declarations: [FeedbackDmpCaseDialog],
  imports: [
    CommonModule,
    FormControlErrorModule,
    AokRequiredErrorMessageModule,
    AokEmailErrorMessageModule,
    ReactiveFormsModule,
    AokCheckboxModule,
    AokDropdownModule,
    AokHintModule,
    AokInputFieldModule,
    AokNotificationBannerModule,
    AokDialogLayoutModule,
    RequiredMarkModule,
    AokDropdownModule,
    AokFullNamePipeModule,
    A11yModule,
    A11yUtilsModule,
    TrimControlDirective,
    AokFullAddressPipe,
  ],
  exports: [FeedbackDmpCaseDialog],
})
export class FeedbackDmpCaseDialogModule {}
