<aok-cockpit-header-page-headline
  [ngClass]="{ 'no-dmp-cases': !hasDmpCases }"
  [headline]="'DMP-Fallübersicht'"
  [hint]="
    hasDmpCases &&
    headerHint +
      (overviewData.lastUpdatedDate ? (overviewData.lastUpdatedDate | date : 'dd.MM.yyyy') : '-') +
      '.' +
      '<br>' +
      'Haben Sie Fragen zu einem Fallverlauf oder fehlen Ihnen Daten?' +
      '<br>' +
      'Rufen Sie uns gerne an. Unter 0511/285-35720 sind wir gerne für Sie da.'
  "
>
</aok-cockpit-header-page-headline>

<div *ngIf="hasDmpCases" class="aok-container sub-headline">
  <h5>Quartal {{ currentQuarter + '/' + currentYear }}</h5>
</div>

<div class="aok-bg-pastel-blue-1 tw-pb-4" *ngIf="hasDmpCases; else noCounts">
  <div class="aok-container">
    <div
      (keyup.enter)="navigateToDmpCategory(card.category)"
      *ngFor="let card of cards"
      [routerLink]="['./', categoryUrls[card.category]]"
      aokFocusable
      class="overview-card {{ card.columns }}"
      routerLinkActive="active"
    >
      <a class="overview-card-link aok-link-1">
        {{ card.linkText }}
        <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
      </a>

      <div class="overview-card-header">
        <aok-svg-icon [name]="card.icon" size="77"></aok-svg-icon>
        <h5>
          <ng-container *ngIf="card.hasTextHeadline; else noTextHeadline">
            {{
              overviewData.counts[card.category] > 0 ? overviewData.counts[card.category] + ' fällig' : 'Alles erledigt'
            }}
          </ng-container>
        </h5>

        <ng-template #noTextHeadline>
          {{ overviewData.counts[card.category] }}
        </ng-template>
      </div>

      <div class="overview-card-content">
        {{ card.content }}
      </div>
    </div>
  </div>
</div>

<ng-template #noCounts>
  <div class="aok-bg-sand tw-col-1-13">
    <div class="aok-container">
      <div class="tw-col-3-11">
        <h5>Für Sie liegen aktuell keine Daten vor.</h5>

        <div class="tw-mt-8">
          <p>
            <ng-container
              *ngIf="isDoctor || (contextOptions$ | async).length === 1; else practiceEmployeeWithMultipleLanrsTemplate"
            >
              Bei weiteren Fragen rund um das Thema DMP wenden Sie sich gerne an das AOK-Curaplan-Team unter
              0511/285-35720.
            </ng-container>

            <ng-template #practiceEmployeeWithMultipleLanrsTemplate>
              <p class="tw-mb-8">Wählen Sie einen anderen Arzt aus, der am DMP teilnimmt, um die Daten zu sehen.</p>
              <p>
                Bei weiteren Fragen rund um das Thema DMP wenden Sie sich gerne an das AOK-Curaplan-Team unter
                0511/285-35720.
              </p>
            </ng-template>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
