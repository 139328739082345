import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AokArticle } from '@aok/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aok-cockpit-article',
  styleUrls: ['./article.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './article.component.html',
})
export class ArticleComponent implements OnInit, OnDestroy {
  article: AokArticle;
  readonly ngDestroy = new Subject<void>();

  constructor(private dom: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.article = data['article'] as AokArticle;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
