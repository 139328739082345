import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

export interface AokCard {
  iconName: string;
  label: string;
  link?: string;
  fn?: () => void;
}

/**
 * A card component that can be used to navigate to a relative path or execute an action.
 *
 * @param iconName: The name of the icon to be displayed on the card.
 * @param label: The label to be displayed on the card.
 * @param link: The link to navigate to when the card is clicked.
 * @param fn: The function to be called when the card is clicked.
 */
@Component({
  selector: 'aok-card',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div *ngIf="!!link || !!fn" (keydown.enter)="onClick()" class="card" aokFocusable (click)="onClick()">
      <aok-svg-icon [name]="iconName" size="24px"></aok-svg-icon>
      <div>
        <p class="aok-text-p2 aok-bold" [innerHTML]="label + '&nbsp;'"></p>
        <aok-svg-icon name="arrow-rounded_right"></aok-svg-icon>
      </div>
    </div>
  `,
})
export class AokCardComponent implements AokCard {
  @Input() iconName: string;
  @Input() label: string;
  @Input() link?: string;
  @Input() fn?: () => void;

  constructor(public router: Router) {}

  onClick(): void {
    if (this.fn) {
      this.fn();
    } else if (this.link) {
      this.router.navigateByUrl(this.link);
    }
  }
}
