<div [routerLink]="tile?.link" tabindex="-1" [class.tw-cursor-pointer]="!tile?.isTileClickable">
  <ng-container *ngIf="tile.image">
    <div class="image">
      <img [src]="safeURL" [alt]="tile.alt ? tile.alt : 'Titelbild'" />
    </div>
  </ng-container>

  <ng-container *ngIf="!tile.image && !tile.hidePlaceholder">
    <div class="placeholder-image">
      <aok-svg-icon name="0000-lebensbaum" [size]="64"></aok-svg-icon>
    </div>
  </ng-container>
</div>

<div class="preview-content" (click)="navigateToLink()">
  <div class="metadata">
    <ng-content select="[metaData]"></ng-content>
  </div>

  <a class="aok-link-1 tile-title" [target]="'_self'" [routerLink]="tile?.link">
    <span>{{ tile?.title }}</span>
    <aok-svg-icon name="arrow-rounded_right" size="16px"></aok-svg-icon>
  </a>

  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
