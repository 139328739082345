import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogBase, DialogRef, PrimitiveBehaviorState } from '@aok/common';
import { KeycloakService } from 'keycloak-angular';
import { from, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccordionOption } from '../../../schema/accordion-option.schema';
import { AokCookieService } from '../../../services';

@Component({
  selector: 'aok-cookie-dialog',
  styleUrls: ['./cookie-dialog.component.scss'],
  templateUrl: './cookie-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AokCookieDialog implements OnDestroy, DialogBase<boolean> {
  public form: UntypedFormGroup = new UntypedFormGroup({
    FUNCTIONAL_COOKIES: new UntypedFormControl({
      value: true,
      disabled: true,
    }),
    MARKETING_COOKIES: new UntypedFormControl(false),
  });

  readonly FUNCTIONAL_COOKIES = 'FUNCTIONAL_COOKIES';
  readonly MARKETING_COOKIES = 'MARKETING_COOKIES';

  readonly hoursToExpire = 72;
  readonly viewCustomPreferences = new PrimitiveBehaviorState<boolean>();
  readonly options: AccordionOption[] = [
    {
      id: this.FUNCTIONAL_COOKIES,
      customLabel: 'Funktionale Cookies',
      customContent:
        '<p>Diese Cookies und Skripte sind erforderlich, um die Kernfunktionalitäten der Webseite bereitstellen zu können. ' +
        'Im Fall dieser Seite zählen folgende Cookies und Skripte dazu: ' +
        '<ul class="cookie-list">' +
        '<li>Erkennung der Browsersprache</li>' +
        '<li>Status Cookie-Zustimmung des Nutzers</li>' +
        '<li>Frontend-Login für AOK-Arztportal</li>' +
        '<li>Status-Cookies für Nutzereinstellungen</li>' +
        '</ul></p>',
    },
    {
      id: this.MARKETING_COOKIES,
      customLabel: 'Marketing Cookies	',
      customContent: `<p>
                        Mithilfe von Marketing-Cookies können wir die Inhalte und Services unserer Website besser an Ihre
                        Interessen und Ihre Nutzungsweise anpassen und unser Angebot für Sie verbessern. Für Statistiken
                        und Auswertungen setzen wir den Analyse-Dienst Matomo ein, den wir auf unseren Servern hosten.
                      </p>`,
    },
  ];
  public isPublic: boolean;
  protected readonly ngDestroys = new ReplaySubject<void>();

  constructor(
    public readonly dialogRef: DialogRef<boolean>,
    protected changeDetectorRef: ChangeDetectorRef,
    protected cookieService: AokCookieService,
    @Optional() private keycloak: KeycloakService,
    private sanitizer: DomSanitizer
  ) {
    this.updateMarketing();
    this.isPublic = !this.keycloak?.getKeycloakInstance();
  }

  ngOnDestroy(): void {
    this.ngDestroys.next();
    this.ngDestroys.complete();
  }

  public acceptAndClose(acceptAll: boolean, hoursToExpire?: number): void {
    this.cookieService.accept(acceptAll ? true : this.form.get(this.MARKETING_COOKIES).value, hoursToExpire);
    this.dialogRef.dispose(true);
  }

  public patchValue(value: boolean): void {
    this.viewCustomPreferences.patch(value);
    this.changeDetectorRef.detectChanges();
  }

  public safeUrl(url: string): SafeUrl {
    const prefix = this.isPublic ? '/publik' : '';
    return this.sanitizer.bypassSecurityTrustUrl(`${prefix}${url}`);
  }

  private updateMarketing(): void {
    from(this.cookieService.hasMarketing())
      .pipe(takeUntil(this.ngDestroys))
      .subscribe((hasMarketing) => {
        this.form.get(this.MARKETING_COOKIES).setValue(hasMarketing);
        this.form.updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
      });
  }
}
