import { Provider } from '@angular/core';
import { SVG_ICON_IMPORT, SvgIconImportConfig } from '@aok/common';
import {
  icon0132Antenna,
  icon0239PriceTag2,
  icon0317Location5,
  icon0344Bell,
  icon0419Undo,
  iconAdressBook,
  iconAlertCircle,
  iconArrowDown,
  iconArrowLeft,
  iconArrowLeftCircle,
  iconArrowRight,
  iconArrowRightCircle,
  iconArrowRoundedDown,
  iconArrowRoundedLeft,
  iconArrowRoundedRight,
  iconArrowRoundedRightup,
  iconArrowRoundedUp,
  iconArrowUp,
  iconBecomeMember,
  iconCalender,
  iconCancelCircleFilled,
  iconCheckmarkCircle,
  iconClose,
  iconCloseSmall,
  iconCustomerCentre,
  iconDelete,
  iconDownload,
  iconEasyLanguage,
  iconEnterLogin,
  iconExit,
  iconFilter4,
  iconHome,
  iconInfoCircle,
  iconLogin,
  iconMail,
  iconOffice,
  iconPhone,
  iconQuestionCircle,
  iconQuestionCircleFilled,
  iconReload,
  iconSearch,
  iconSettings,
  iconSignLanguage,
  iconStar,
  iconStarFilled,
  iconTextChatBubble,
} from '@aok/functional-icons';
import {
  icon0180FileMinus,
  iconBellLight,
  iconBellThin,
  iconCheckmark,
  iconEasySpeech,
  iconExclamationMark,
  iconNoPrint,
  iconSessionClock,
  iconSharedReport,
} from '@aok/icons';
import {
  icon0000Checklist,
  icon0000Cookie,
  icon0000FileText,
  icon0000Hospital,
  icon0000Lebensbaum,
  icon0108Lock,
  icon0111Key,
  icon0151Envelope,
  icon0181FileAdd,
  icon0182FileCheck,
  icon0184Files,
  icon0210Register,
  icon0214News,
  icon0284Profile,
  icon0287User,
  icon0348CashEuro,
  icon0393Calendar31,
  icon0804FileSearch,
  icon0858CheckmarkCircle,
  icon0859CrossCircle,
  iconIntegrierteVersorgung,
} from '@aok/illustrative-icons';
import {
  iconAngleDoubleLeft,
  iconAngleDown,
  iconAngleLeft,
  iconAngleRight,
  iconAngleUp,
  iconAt,
  iconBell,
  iconBuilding,
  iconCheck,
  iconCheckCircle,
  iconChevronDoubleRight,
  iconChevronLeft,
  iconChevronRight,
  iconEllipsisV,
  iconExclamationTriangle,
  iconFileAlt,
  iconMailbox,
  iconMailBulk,
  iconMinus,
  iconPenNib,
  iconPhoneAlt,
  iconPhoneRotary,
  iconPlus,
  iconShareAll,
  iconShareAlt,
  iconText,
  iconTimes,
  iconUserCheck,
  iconUserMd,
  iconVoicemail,
} from '@vitagroup/icons';

export const APP_ICON_IMPORT_PROVIDER: Provider = {
  provide: SVG_ICON_IMPORT,
  useValue: {
    icons: [
      iconEasySpeech,
      iconCheckCircle,
      iconBuilding,
      iconTimes,
      iconPenNib,
      iconAngleDoubleLeft,
      iconFileAlt,
      iconAngleRight,
      iconAngleLeft,
      iconAngleDown,
      iconAngleUp,
      iconDownload,
      iconInfoCircle,
      iconEllipsisV,
      iconShareAll,
      iconShareAlt,
      iconSearch,
      iconPhoneRotary,
      iconArrowRight,
      iconArrowLeft,
      iconMailbox,
      iconUserMd,
      iconAt,
      icon0000Cookie,
      iconPlus,
      iconMinus,
      iconCheck,
      iconExclamationTriangle,
      iconExclamationMark,
      iconCheckmark,
      iconSearch,
      iconChevronDoubleRight,
      iconChevronLeft,
      iconChevronRight,
      iconBell,
      iconBellThin,
      iconClose,
      iconAngleUp,
      iconBellLight,
      iconBecomeMember,
      iconAlertCircle,
      iconMailBulk,
      iconMailbox,
      iconVoicemail,
      iconPhoneAlt,
      iconText,
      iconQuestionCircle,
      iconEasyLanguage,
      iconSignLanguage,
      iconPhone,
      iconEnterLogin,
      iconCancelCircleFilled,
      iconMail,
      iconCustomerCentre,
      iconTextChatBubble,
      iconLogin,
      iconArrowRoundedLeft,
      iconArrowRoundedRight,
      iconOffice,
      iconArrowRoundedUp,
      iconArrowRoundedDown,
      iconCalender,
      iconCloseSmall,
      iconQuestionCircleFilled,
      icon0000Hospital,
      icon0000Checklist,
      icon0284Profile,
      icon0858CheckmarkCircle,
      icon0000Lebensbaum,
      icon0287User,
      iconArrowDown,
      iconArrowUp,
      iconExit,
      icon0000FileText,
      icon0151Envelope,
      icon0111Key,
      icon0214News,
      icon0348CashEuro,
      iconHome,
      icon0344Bell,
      iconCheckmarkCircle,
      iconExclamationTriangle,
      icon0393Calendar31,
      iconDelete,
      icon0132Antenna,
      iconArrowRoundedRightup,
      iconSharedReport,
      iconArrowRightCircle,
      iconArrowLeftCircle,
      icon0184Files,
      icon0181FileAdd,
      icon0180FileMinus,
      icon0210Register,
      iconReload,
      icon0182FileCheck,
      iconIntegrierteVersorgung,
      icon0859CrossCircle,
      icon0108Lock,
      iconAdressBook,
      icon0419Undo,
      iconNoPrint,
      iconUserCheck,
      iconSessionClock,
      icon0804FileSearch,
      icon0239PriceTag2,
      icon0317Location5,
      iconFilter4,
      iconStar,
      iconStarFilled,
      iconSettings,
    ],
    dataSelector: 'data',
    idSelector: 'name',
    idRewrite: [{ replace: '_', with: '-' }],
  } as SvgIconImportConfig,
};
